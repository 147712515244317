import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { v4 as uuid } from 'uuid'

type CustomHeaders = {
  'x-app-trace-token': string
  'x-firebase-id-token'?: string
}

export const useCoreApi = () => {
  const getCoreApiClient = async () => {
    const { currentUser } = getAuth()
    const idToken = await currentUser?.getIdToken()
  
    const customHeaders: CustomHeaders = {
      'x-app-trace-token': uuid(),
    }
    
    if (idToken) {
      customHeaders['x-firebase-id-token'] = idToken
    }
  
    const coreApi = axios.create({
      baseURL: process.env.REACT_APP_CORE_API_URL,
      headers: customHeaders,
      timeout: 30000,
      responseType: 'json'
    })

    return coreApi
  }

  return {
    getCoreApiClient
  }
}

export default useCoreApi
