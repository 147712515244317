import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import errcode from 'err-code'
import { useSnackbar } from 'notistack'

import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MuiPaper, { PaperProps } from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

import { Theme, useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import useUser from '../hooks/useUser'
import { Facilities, GasStation } from '../types'
import Error40X from './Error40X'
import '../static/styles/main.css'

const logger = new Logger({
  filePath: '@/components/GasStationOperations'
})

type StyledTheme = {
  theme: Theme,
}

type Days = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

type DaysOpen = {
  [key in Days]: boolean
}

type DaysOpenClose = {
  [key in Days]: {
    open: React.RefObject<HTMLInputElement>
    close: React.RefObject<HTMLInputElement>
  }
}

type FormErrors = {
  dayPicker?: 'FIELD_REQUIRED'
  daysOpen: {
    monday?: 'FIELD_REQUIRED'
    tuesday?: 'FIELD_REQUIRED'
    wednesday?: 'FIELD_REQUIRED'
    thursday?: 'FIELD_REQUIRED'
    friday?: 'FIELD_REQUIRED'
    saturday?: 'FIELD_REQUIRED'
    sunday?: 'FIELD_REQUIRED'
  }
  submit?: 'REQUEST_FAILED' | 'MISSING_BUSINESSACCOUNTID'
}

type GasStationResponse = {
  data: {
    result?: GasStation
  }
}

type Switches = {
  isOpen247: boolean
  hasSelfServicePumps: boolean
}

type GasStationLocationProps = {
  gasStation?: GasStation
}

type DefaultFormValues = {
  switches: Switches
  facilities: Facilities
  daysOpen: DaysOpen
  daysOpenCloseTime: {
    [key in Days]: {
      open: string
      close: string
    }
  }
}

const GasStationLocationContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const GasStationFormContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  margin: 0,
  '& .MuiFormControlLabel-label': {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    }
  },
}))

const NoMarginFormHelperText = styled(FormHelperText)`
  ${({ theme }: StyledTheme) => `
    &.MuiFormHelperText-root {
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const Paper = styled(MuiPaper)<PaperProps>(({ theme }) => ({
  minHeight: '100%',
  width: '100%',
  overflow: 'scroll',
  [theme.breakpoints.up('md')]: {
    minWidth: '450px',
    overflow: 'auto',
  },
}))

export default function GasStationLocation(props: GasStationLocationProps) {
  const { gasStation } = props

  const { enqueueSnackbar } = useSnackbar()
  const { getCoreApiClient } = useCoreApi()
  const { currentUser } = useUser()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const Params = useParams()
  const paramsBusinessAccountId = Params.businessAccountId
  const paramsGasStationId = Params.gasStationId

  const [hasIni, setHasIni] = useState<boolean>(false)

  const [defaultFormValues, setDefaultFormValues] = useState<DefaultFormValues>()

  const [daysOpen, setDaysOpen] = useState<DaysOpen>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  })

  const daysOpenRef: DaysOpenClose = {
    monday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    tuesday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    wednesday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    thursday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    friday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    saturday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    },
    sunday: {
      open: useRef<HTMLInputElement>(null),
      close: useRef<HTMLInputElement>(null),
    }
  }

  const [switches, setSwitches] = useState<Switches>({
    isOpen247: false,
    hasSelfServicePumps: false,
  })
  const [facilities, setFacilities] = useState<Facilities>({
    restrooms: false,
    airWater: false,
    foodBeverage: false,
    autoRepair: false,
    carWash: false,
    atm: false,
  })

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({ daysOpen: {} })
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const handleSwitchChange = (key: 'isOpen247' | 'hasSelfServicePumps' | 'isDealerOperatedFranchise', event: React.ChangeEvent<HTMLInputElement>) => {
    const newSwitches = {
      ...switches,
      [key]: event.target.checked
    }

    if (key === 'isOpen247') {
      setDaysOpen({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      })

      setDefaultFormValues({
        switches: {
          ...switches,
        },
        facilities: {
          ...facilities,
        },
        daysOpen: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        },
        daysOpenCloseTime: {
          monday: {
            open: '',
            close: '',
          },
          tuesday: {
            open: '',
            close: '',
          },
          wednesday: {
            open: '',
            close: '',
          },
          thursday: {
            open: '',
            close: '',
          },
          friday: {
            open: '',
            close: '',
          },
          saturday: {
            open: '',
            close: '',
          },
          sunday: {
            open: '',
            close: '',
          },
        }
      })
    }

    setSwitches({ ...newSwitches })

    setHasUnsavedChanges(true)
  }

  const handleFacilityCheckboxChange = (key: 'restrooms' | 'airWater' | 'foodBeverage' | 'autoRepair' | 'carWash' | 'atm', event: React.ChangeEvent<HTMLInputElement>) => {
    const newFacilities = {
      ...facilities,
      [key]: event.target.checked
    }

    setFacilities({ ...newFacilities })

    setHasUnsavedChanges(true)
  }

  const handleDaysOpenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaysOpen({
      ...daysOpen,
      [event.target.name]: event.target.checked,
    })

    setHasUnsavedChanges(true)
  }

  const handleDayOpenCloseTimeChange = () => {
    setHasUnsavedChanges(true)
  }

  const submitInfo = async () => {
    try {
      setIsFormDisabled(true)

      let formErrorsFound: FormErrors = { daysOpen: {} }
      const { hasSelfServicePumps, isOpen247 } = switches

      const daysOpenInfo = {
        monday: {
          isOpen: false,
          open: '',
          close: '',
        },
        tuesday: {
          isOpen: false,
          open: '',
          close: '',
        },
        wednesday: {
          isOpen: false,
          open: '',
          close: '',
        },
        thursday: {
          isOpen: false,
          open: '',
          close: '',
        },
        friday: {
          isOpen: false,
          open: '',
          close: '',
        },
        saturday: {
          isOpen: false,
          open: '',
          close: '',
        },
        sunday: {
          isOpen: false,
          open: '',
          close: '',
        }
      }

      if (isOpen247 === false) {
        let hasNoDaySelected = true
        Object.keys(daysOpenInfo).forEach((d) => {
          let day = d as Days
          if (daysOpen && daysOpen[day] === true) {
            hasNoDaySelected = false
            daysOpenInfo[day].isOpen = true
          }
        })

        if (hasNoDaySelected === true) {
          formErrorsFound.dayPicker = 'FIELD_REQUIRED'
        }

        Object.keys(daysOpenInfo).forEach((d) => {
          let day = d as Days
          if (daysOpen && daysOpen[day] === true) {
            if (!daysOpenRef[day].open.current?.value || !daysOpenRef[day].close.current?.value) {
              formErrorsFound.daysOpen[day] = 'FIELD_REQUIRED'
              return
            }

            const openValue = daysOpenRef[day].open.current?.value
            const closeValue = daysOpenRef[day].close.current?.value

            if (!openValue || openValue.trim().length < 1 || !closeValue || closeValue.trim().length < 1) {
              formErrorsFound.daysOpen[day] = 'FIELD_REQUIRED'
            } else {
              daysOpenInfo[day].open = openValue.trim()
              daysOpenInfo[day].close = closeValue.trim()
            }
          }
        })
      }

      if (Object.keys(formErrorsFound.daysOpen).length > 0 || formErrorsFound.dayPicker) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      // No errors found
      setFormErrors({ daysOpen: {} })

      const payload = {
        businessAccountId: currentUser?.businessAccount?.businessAccountId,
        facilities,
        hasSelfServicePumps,
        isOpen247,
        daysOpen: daysOpenInfo,
      }

      const coreApi = await getCoreApiClient()

      const putGasStationOperationsResponse = await coreApi.put(`/business/${paramsBusinessAccountId}/gas-stations/${paramsGasStationId}/operations`, {
        ...payload,
      }) as GasStationResponse

      if (!putGasStationOperationsResponse?.data?.result?.uuid) {
        throw errcode(new Error('Failed to update gas station location'), 'InvalidResponse')
      }

      setHasUnsavedChanges(false)
      setIsFormDisabled(false)
      enqueueSnackbar('Changes saved', { variant: 'success' })
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Submit gas station operations failed', { errMessage, errCode })

      let formErrorsFound: FormErrors = { daysOpen: {} }

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      formErrorsFound.submit = 'REQUEST_FAILED'

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleOnSubmitClick = () => {
    submitInfo()
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        if (!isSubscribed) {
          return false
        }

        const newDefaultFormValues: DefaultFormValues = {
          facilities: {
            airWater: false,
            atm: false,
            autoRepair: false,
            carWash: false,
            foodBeverage: false,
            restrooms: false,
          },
          switches: {
            isOpen247: false,
            hasSelfServicePumps: false,
          },
          daysOpen: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
          daysOpenCloseTime: {
            monday: {
              open: '',
              close: '',
            },
            tuesday: {
              open: '',
              close: '',
            },
            wednesday: {
              open: '',
              close: '',
            },
            thursday: {
              open: '',
              close: '',
            },
            friday: {
              open: '',
              close: '',
            },
            saturday: {
              open: '',
              close: '',
            },
            sunday: {
              open: '',
              close: '',
            }
          }
        }

        if (gasStation?.facilities) {
          setFacilities({
            ...gasStation?.facilities
          })

          newDefaultFormValues.facilities = {
            ...gasStation?.facilities
          }
        }

        if (gasStation?.isOpen247 !== undefined && gasStation?.hasSelfServicePumps !== undefined) {
          setSwitches({
            isOpen247: gasStation.isOpen247,
            hasSelfServicePumps: gasStation.hasSelfServicePumps,
          })

          newDefaultFormValues.switches = {
            isOpen247: gasStation.isOpen247,
            hasSelfServicePumps: gasStation.hasSelfServicePumps,
          }
        }

        if (gasStation?.daysOpen !== undefined) {
          const newDaysOpen = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          }

          Object.keys(gasStation.daysOpen).forEach((d) => {
            const day = d as Days
            newDefaultFormValues.daysOpen[day] = gasStation.daysOpen[day].isOpen
            newDefaultFormValues.daysOpenCloseTime[day] = {
              open: gasStation.daysOpen[day].open ? gasStation.daysOpen[day].open : '',
              close: gasStation.daysOpen[day].close ? gasStation.daysOpen[day].close : '',
            }

            newDaysOpen[day] = gasStation.daysOpen[day].isOpen
          })

          setDaysOpen({
            ...newDaysOpen
          })
        }

        if (currentUser?.isFewlsyStaff) {
          setIsFormDisabled(true)
        }


        setDefaultFormValues({
          ...newDefaultFormValues
        })

        setHasIni(true)
      } catch (err) {
        logger.error('Failed to load gas station info')
      }
    }

    if (gasStation) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [gasStation, currentUser])

  if (currentUser?.isFewlsyStaff) {
    return <Error40X />
  }

  if (!hasIni) {
    return <React.Fragment />
  }

  return <GasStationLocationContainer maxWidth={false}>
    <Box mb={mdUp ? 5 : 2} maxWidth={500}>
      <Typography variant="h5">Update Operations</Typography>
    </Box>
    <GasStationFormContainer>
      <Box maxWidth={mdUp ? 450 : '100%'} mr={mdUp ? 5 : 0} mb={5}>
        <Paper>
          <Box>
            <Box p={mdUp ? 3 : 2}>
              <Box mb={3}>
                <Typography variant="h6">Operational Information</Typography>
              </Box>
              <Stack direction="column" spacing={3}>
                <Box>
                  <FormControlLabel
                    control={<Switch
                      defaultChecked={defaultFormValues?.switches.isOpen247}
                      onChange={(e) => handleSwitchChange('isOpen247', e)}
                      disabled={isFormDisabled}
                    />}
                    label="Is the location open 24/7?"
                  />
                </Box>
                <>
                  {!switches.isOpen247 &&
                    <Box>
                      <Box>
                        <Stack direction="column" spacing={2}>
                          <Box width="100%">
                            <FormControl error={formErrors.dayPicker === 'FIELD_REQUIRED'}>
                              <FormLabel>Check the days you are open</FormLabel>
                              <Box width="100%">
                                <FormGroup>
                                  <Stack direction="row" spacing={0} justifyContent={smDown ? 'flex-start' : 'center'} flexWrap="wrap">
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.monday} name="monday" />
                                      }
                                      label="MO"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.tuesday} name="tuesday" />
                                      }
                                      label="TU"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.wednesday} name="wednesday" />
                                      }
                                      label="WE"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.thursday} name="thursday" />
                                      }
                                      label="TH"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.friday} name="friday" />
                                      }
                                      label="FR"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.saturday} name="saturday" />
                                      }
                                      label="SA"
                                      labelPlacement="top"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox onChange={handleDaysOpenChange} defaultChecked={defaultFormValues?.daysOpen.sunday} name="sunday" />
                                      }
                                      label="SU"
                                      labelPlacement="top"
                                    />
                                  </Stack>
                                </FormGroup>
                              </Box>
                              <>
                                {formErrors.dayPicker === 'FIELD_REQUIRED' &&
                                  <NoMarginFormHelperText>Must have at least one day checked</NoMarginFormHelperText>
                                }
                              </>
                            </FormControl>
                          </Box>
                          <Stack direction="column" spacing={2}>
                            {daysOpen &&
                              Object.keys(daysOpen).map((value, index) => {
                                const day = value as Days
                                const label = day[0].toUpperCase() + day.slice(1)

                                let timeOpenDefault = ''
                                let timeCloseDefault = ''
                                if (gasStation?.daysOpen && gasStation?.daysOpen[day].open) {
                                  timeOpenDefault = gasStation?.daysOpen[day].open
                                }

                                if (gasStation?.daysOpen && gasStation?.daysOpen[day].close) {
                                  timeCloseDefault = gasStation?.daysOpen[day].close
                                }

                                const isDayChecked = (daysOpen && daysOpen !== undefined && daysOpen !== null) ? daysOpen[day] : defaultFormValues?.daysOpen[day] 

                                return <React.Fragment key={day}>
                                  <>
                                    {index < 1 && (daysOpen.monday || daysOpen.tuesday || daysOpen.wednesday || daysOpen.thursday || daysOpen.friday || daysOpen.saturday || daysOpen.sunday) &&
                                      <Box hidden={!mdUp}>
                                        <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="flex-start">
                                          <Box width={80}>
                                            <FormLabel>Day</FormLabel>
                                          </Box>
                                          <Box width={125}>
                                            <FormLabel>Opens at</FormLabel>
                                          </Box>
                                          <Box width={125}>
                                            <FormLabel>Closes at</FormLabel>
                                          </Box>
                                        </Stack>
                                      </Box>
                                    }
                                  </>
                                  <Box hidden={!isDayChecked}>
                                    <FormControl error={formErrors.daysOpen[day] === 'FIELD_REQUIRED'}>
                                      <Stack direction={mdUp ? 'row' : 'column'} spacing={mdUp ? 3 : 1} justifyContent="center">
                                        <Box width={80} display="flex" alignItems="center">
                                          <Stack direction="column" justifyContent="center">
                                            <Typography variant="body2" color={formErrors.daysOpen[day] === 'FIELD_REQUIRED' ? 'error' : 'GrayText'}>{label}</Typography>
                                            <>
                                              {formErrors.daysOpen[day] === 'FIELD_REQUIRED' &&
                                                <NoMarginFormHelperText variant="outlined">Field required</NoMarginFormHelperText>
                                              }
                                            </>
                                          </Stack>
                                        </Box>
                                        <Stack direction={smDown ? 'column' : 'row'} spacing={1} flexWrap="wrap">
                                          <Box width={125}>
                                            <input ref={daysOpenRef[day].open} onChange={handleDayOpenCloseTimeChange} type="time" className={`appui__input-time ${formErrors.daysOpen[day] === 'FIELD_REQUIRED' ? 'appui__input--error' : ''}`} defaultValue={timeOpenDefault} />
                                          </Box>
                                          <Box width={125}>
                                            <input ref={daysOpenRef[day].close} onChange={handleDayOpenCloseTimeChange} type="time" className={`appui__input-time ${formErrors.daysOpen[day] === 'FIELD_REQUIRED' ? 'appui__input--error' : ''}`} defaultValue={timeCloseDefault} />
                                          </Box>
                                        </Stack>
                                      </Stack>
                                    </FormControl>
                                  </Box>
                                </React.Fragment>
                              })
                            }
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  }
                </>
                <Box mb={3}>
                  <FormControlLabel
                    control={<Switch
                      defaultChecked={defaultFormValues?.switches.hasSelfServicePumps}
                      onChange={(e) => handleSwitchChange('hasSelfServicePumps', e)}
                      disabled={isFormDisabled}
                    />}
                    label="Does the location have self-service pumps?"
                  />
                </Box>
                <Box>
                  <FormLabel component="legend">Facilities</FormLabel>
                  <FormGroup row={false}>
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.airWater} onChange={(e) => handleFacilityCheckboxChange('airWater', e)} disabled={isFormDisabled} name="facilityAirWater" />
                      }
                      label="Air and Water"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.atm} onChange={(e) => handleFacilityCheckboxChange('atm', e)} disabled={isFormDisabled} name="facilityAtm" />
                      }
                      label="ATM"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.autoRepair} onChange={(e) => handleFacilityCheckboxChange('autoRepair', e)} disabled={isFormDisabled} name="facilityAutoRepair" />
                      }
                      label="Car Servicing"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.carWash} onChange={(e) => handleFacilityCheckboxChange('carWash', e)} disabled={isFormDisabled} name="facilityCarWash" />
                      }
                      label="Car Wash"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.foodBeverage} onChange={(e) => handleFacilityCheckboxChange('foodBeverage', e)} disabled={isFormDisabled} name="facilityFoodBeverage" />
                      }
                      label="Food and Beverage"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox defaultChecked={defaultFormValues?.facilities.restrooms} onChange={(e) => handleFacilityCheckboxChange('restrooms', e)} disabled={isFormDisabled} name="facilityRestrooms" />
                      }
                      label="Restrooms"
                    />
                  </FormGroup>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box maxWidth={mdUp ? 450 : '100%'} mr={mdUp ? 5 : 0} mb={5}>
        <Box width="100%">
          <>
            {!currentUser?.isFewlsyStaff &&
              <Box>
                <Button variant="contained" onClick={() => handleOnSubmitClick()} disabled={!hasUnsavedChanges || isFormDisabled}>{isFormDisabled ? 'Saving...' : 'Save Changes'}</Button>
                <>
                  {hasUnsavedChanges &&
                    <Box mt={2}>
                      <Typography variant="body2" color="error">You have unsaved changes</Typography>
                    </Box>
                  }
                </>
              </Box>
            }
          </>
        </Box>
      </Box>
    </GasStationFormContainer>
  </GasStationLocationContainer>
}
