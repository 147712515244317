import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate, useParams } from 'react-router-dom'
import errcode from 'err-code'

import Box  from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import { Theme } from '@mui/material/styles'

import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import useUser from '../hooks/useUser'
import useCoreApi from '../hooks/useCoreApi'
import * as Types from '../types'

import '../static/styles/main.css'
import imgAppIcon from '../static/images/app-icon.svg'
import imgGoogleIcon from '../static/images/google-icon.png'

const logger = new Logger({
  filePath: '@/screens/AuthBusinessUserInvite'
})

type BusinessUserInviteResponse = {
  data: {
    result?: BusinessUserInviteInfo
  }
}

type BusinessUserInviteInfo = {
  businessUserInvite: Types.BusinessUserInvite
  bussinessAccount: Types.BusinessAccount
}

type StyledTheme = {
  theme: Theme
}

type GoogleBtnProps = {
  children: React.ReactNode
}

type FormErrors = {
  submit?: 'EMAIL_DID_NOT_MATCH' | 'REQUEST_FAILED'
}

export type CreateUserResponse = {
  data: {
    result?: Types.UserApiResponse
  }
}

const AuthContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
`

export default function BusinessUserInviteScreen() {
  const navigate = useNavigate()
  const [isProviderAuthenticating, setIsProviderAuthenticating] = useState(false)

  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [inviteInfo, setInviteInfo] = useState<BusinessUserInviteInfo>()

  const { businessUserInviteId } = useParams()
  const { loginUser } = useUser()
  const { getCoreApiClient } = useCoreApi()
  const pageRoutes = usePageRoutes()

  const handleOnGoogleRegisterBtnClick = async () => {
    try {
      setIsProviderAuthenticating(true)
      setFormErrors({})

      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      const result = await signInWithPopup(auth, provider)

      if (!result.user) {
        throw errcode(new Error('Registration response does not include `user` property'), 'ProviderSignInFaultyUserError')
      }

      if (!result.user.email) {
        throw errcode(new Error('Google sign in did not return an email address'), 'ProviderSignInFaultyEmailError')
      }

      if (result.user.email !== inviteInfo?.businessUserInvite.email) {
        throw errcode(new Error('Email signed in did not match invite email address'), 'SignInEmailDidNotMatchInviteEmailError')
      }

      const coreApi = await getCoreApiClient()
      const createUserResponse = await coreApi.post('/users', {
        email: result.user.email,
        displayName: result.user.displayName,
        country: 'PH',
        provider: 'GOOGLE',
        providerUid: result.user.uid,
        businessUserInviteId
      }, {
        params: {
          type: 'join-business'
        }
      }) as CreateUserResponse

      if (!createUserResponse || !createUserResponse.data || !createUserResponse.data.result) {
        throw errcode(new Error('Missing result in CreateUserResponse'), 'CreateUserFailed')
      }

      const currentUser = await loginUser(result.user.uid)
      navigate(pageRoutes.getPrivatePages(currentUser.businessAccount?.businessAccountId || 'undefined').home, { replace: true })
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data.error.message || err.message

      logger.error('Login failed', {
        errCode,
        errMessage,
        err,
      })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'SignInEmailDidNotMatchInviteEmailError') {
        formErrorsFound.submit = 'EMAIL_DID_NOT_MATCH'
      } else {
        formErrorsFound.submit = 'REQUEST_FAILED'
      }

      setFormErrors({ ...formErrorsFound })
      setIsProviderAuthenticating(false)
    }
  }

  const GoogleBtn = ({ children }: GoogleBtnProps) => {
    return <button className="appui__btn appui__auth-btn" onClick={handleOnGoogleRegisterBtnClick}>{ children }</button>
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getBusinessUserInviteResponse = await coreApi.get(`/public/business/user-invites/${businessUserInviteId}`) as BusinessUserInviteResponse
  
        if (!isSubscribed) {
          return
        }
  
        if (!getBusinessUserInviteResponse?.data?.result || !getBusinessUserInviteResponse?.data?.result.businessUserInvite || ! getBusinessUserInviteResponse?.data?.result.bussinessAccount) {
          throw errcode(new Error('Failed to fetch business user invite'), 'GetBusinessUserInviteError')
        }

        setInviteInfo(getBusinessUserInviteResponse.data.result)
      } catch (err: any) {
        const errCode = err.response.data?.error?.code || err.code
        const errMessage = err.response.data.error.message || err.message

        logger.error('Get business user invite failed', {
          errCode,
          errMessage,
          err,
        })
      }
    }

    if (!inviteInfo) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [inviteInfo, businessUserInviteId, getCoreApiClient])

  if (!inviteInfo) {
    return <Box>
      <LinearProgress color="primary" />
    </Box>
  }

  if (isProviderAuthenticating) {
    return <AuthContainer>
      <Box mb={3}>
        <img src={imgAppIcon} width={64} height={64} alt="Fewlsy Icon" />
      </Box>
      <Box>
        <Typography variant="h5">Authenticating</Typography>
      </Box>
    </AuthContainer>
  }

  return <AuthContainer>
    <Box mb={5}>
      <img src={imgAppIcon} width={128} height={128} alt="Fewlsy Icon" />
    </Box>
    <Box mb={5}>
      <Typography variant="h5"><b>{inviteInfo.bussinessAccount.ownedByUser?.displayName}</b> invited you to join their Fewlsy Business account</Typography>
    </Box>
    <Box mb={5}>
      <Box display="flex" justifyContent="center">
        <GoogleBtn>
          <img src={imgGoogleIcon} width={32} height={32} alt="Join with Google" />
          <span className="appui__auth-btn-text">Join with Google</span>
        </GoogleBtn>
      </Box>
      <>
        {!formErrors.submit &&
          <Box mt={2}><Typography>Make sure to use your <b>{inviteInfo.businessUserInvite.email}</b> email address</Typography></Box>
        }
      </>
      <>
        {formErrors.submit === 'REQUEST_FAILED' &&
          <Box mt={2}><Typography color="error">Request failed. Please refresh the browser tab and try again.</Typography></Box>
        }
      </>
      <>
        {formErrors.submit === 'EMAIL_DID_NOT_MATCH' &&
          <Box mt={2}><Typography color="error">You must sign in with your <b>{inviteInfo.businessUserInvite.email}</b> email address</Typography></Box>
        }
      </>
    </Box>
  </AuthContainer>
}
