import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Theme, useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'

import GasStationProducts from './GasStationProducts'
import GasStationLocation from './GasStationLocation'
import GasStationSettings from './GasStationSettings'
import GasStationEngagement from './GasStationEngagement'
import GasStationOperations from './GasStationOperations'
import Error40X from './Error40X'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import useGasStationBrand from '../hooks/useGasStationBrand'
import { useUser } from '../hooks/useUser'
import * as Types from '../types'

const logger = new Logger({
  filePath: '@/components/GasStation'
})

type StyledTheme = {
  theme: Theme,
}

export type GetGasStationResponse = {
  data: {
    result?: Types.GasStation
  }
}

export type GetFuelProductsResponse = {
  data: {
    result?: Types.FuelProduct[]
  }
}

const TabsContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `}
`

type TabKeys = 'products' | 'engagement' | 'operations' | 'businessInfo' | 'settings' 
const tabKeyLabel = {
  products: 'Products',
  engagement: 'Engagement',
  operations: 'Operations',
  businessInfo: 'Business Info',
  settings: 'Settings'
}
const getTabsSettings = (currentUser: Types.User | null, gasStationId: string) => {
  const showTabs = {
    products: false,
    engagement: false,
    operations: false,
    businessInfo: false,
    settings: false,
  }
  let tabList: string[] = []

  if (!currentUser?.isFewlsyStaff) {
    if (currentUser?.businessAccount?.isAdmin) {
      showTabs.products = true
      showTabs.engagement = true
      showTabs.operations = true
      showTabs.businessInfo = true
      showTabs.settings = true
      tabList = ['Products', 'Engagement', 'Operations', 'Business Info', 'Settings']
    }
  
    if (!currentUser?.businessAccount?.isAdmin && currentUser?.businessAccount?.permissions) {
      const currentUserPermissions = currentUser.businessAccount.permissions.filter(p => p.type === 'gas-station-permission' && p.gasStationId === gasStationId)[0]
      if (currentUserPermissions && currentUserPermissions.type === 'gas-station-permission') {
        if (currentUserPermissions.products) {
          showTabs.products = true
          tabList.push('Products')
        }
  
        if (currentUserPermissions.engagement) {
          showTabs.engagement = true
          tabList.push('Engagement')
        }
  
        if (currentUserPermissions.operations) {
          showTabs.operations = true
          tabList.push('Operations')
        }
  
        if (currentUserPermissions.businessInfo) {
          showTabs.businessInfo = true
          tabList.push('Business Info')
        }
      }
    }
  }

  if (currentUser?.isFewlsyStaff) {
    showTabs.products = false
    showTabs.engagement = false
    showTabs.operations = false
    showTabs.businessInfo = true
    showTabs.settings = false
    tabList = ['Business Info']
  }

  return { tabList, showTabs }
}

export default function GasStation() {
  const { currentUser } = useUser()
  const { getBrandByCode } = useGasStationBrand()
  const { getCoreApiClient } = useCoreApi()
  const { gasStationId, businessAccountId } = useParams()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [gasStation, setGasStation] = useState<Types.GasStation>()
  const [activeTab, setActiveTab] = useState(0)
  const [gasStationNotFound, setGasStationNotFound] = useState<boolean>(false)
  const [hashTabChecked, setHashTabChecked] = useState<boolean>(false)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const { tabList } = getTabsSettings(currentUser, gasStationId || '')
    const tabSelected = tabList[newValue]
    let tabKeySelected = ''
    Object.keys(tabKeyLabel).forEach((k) => {
      const key = k as TabKeys
      if (tabKeyLabel[key] === tabSelected) {
        tabKeySelected = key
      }
    })

    window.location.hash = `#${tabKeySelected}`
    setActiveTab(newValue)
  }

  useEffect(() => {
    let isSubscribed = true

    /**
     * Get gas station info on page load
     */
    const effect = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getGasStationResponse = await coreApi.get(`/business/${businessAccountId}/gas-stations/${gasStationId}`) as GetGasStationResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getGasStationResponse.data.result) {
          setGasStation({
            ...getGasStationResponse.data.result
          })
        }
      } catch (err: any) {
        if (err?.response?.data?.error?.code) {
          logger.error('Failed to get gas station info', { error: err.response.data.error })

          if (err.response.data.error.code === 'GasStationNotFoundError') {
            setGasStationNotFound(true)
          }
        }
      }
    }

    if (!gasStation) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [gasStationId, gasStation, businessAccountId, getCoreApiClient])

  useEffect(() => {
    let isSubscribed = true

    /**
     * Sets the active tab based on hash tab on page load
     */
    const effect = () => {
      if (!isSubscribed) {
        return false
      }

      const hashTab = window.location.hash.split('#')[1] as TabKeys
      if (!['products', 'engagement', 'operations', 'businessInfo', 'settings'].includes(hashTab)) {
        return false
      }

      const { tabList } = getTabsSettings(currentUser, gasStationId || '')
      if (tabList.indexOf(tabKeyLabel[hashTab]) > -1) {
        setActiveTab(tabList.indexOf(tabKeyLabel[hashTab]))
      }
      setHashTabChecked(true)
    }

    if (!hashTabChecked) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [setHashTabChecked, hashTabChecked, setActiveTab, currentUser, gasStationId])

  const { tabList, showTabs } = getTabsSettings(currentUser, gasStationId || '')

  if (gasStationNotFound) {
    return <Error40X />
  }

  if (!currentUser?.businessAccount?.isAdmin && tabList.length < 1) {
    return <Error40X />
  }

  return <Box>
    <>
      {!gasStation &&
        <LinearProgress />
      }
    </>
    <>
      {gasStation &&
        <Box>
          <Box p={2}>
            <Stack direction={mdUp ? 'row' : 'column'} spacing={mdUp ? 2 : 0} alignItems={mdUp ? 'center' : 'flex-start'}>
              <>
                {gasStation.status === 'REJECTED' &&
                  <Tooltip title="This gas station location has been rejected." arrow>
                    <Chip label="Rejected" color="error" size="small" />
                  </Tooltip>
                }
              </>
              <>
                {gasStation.status === 'PENDING_REVIEW' &&
                  <Tooltip title="This gas station location is still in review. Review process can take up to 3 or more business days." arrow>
                    <Chip label="Pending Review" color="warning" size="small" />
                  </Tooltip>
                }
              </>
              <>
                {gasStation.status === 'ACTIVE' &&
                  <Chip label="Active" color="info" size="small" />
                }
              </>
              <Typography variant="h6">{gasStation.label}</Typography>
              <Typography variant="body1">{gasStation.businessName}</Typography>
              <Typography variant="body1">{getBrandByCode(gasStation.brand).name}</Typography>
            </Stack>
            <>
              {gasStation.status === 'REJECTED' &&
                <Box mt={2} display="inline-block">
                  <Alert severity="error">
                    <Box mb={2} maxWidth={600}>
                      <Typography variant="body1">This gas station location has been rejected by our review team. You can still update your info and resubmit this property for review by going to the BUSINESS INFO tab and clicking "Update Location".</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">The following issues were found:</Typography>
                      <ul>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.isRequiredFieldsComplete === false &&
                            <li><Typography variant="body2">Missing required fields</Typography></li>
                          }
                        </>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.isAddressLatLongCorrect === false &&
                            <li><Typography variant="body2">Invalid geo coordinates</Typography></li>
                          }
                        </>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.hasBusinessPermit === false &&
                            <li><Typography variant="body2">Did not submit Business Permit</Typography></li>
                          }
                        </>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.hasDtiPermit === false &&
                            <li><Typography variant="body2">Did not submit DTI Permit</Typography></li>
                          }
                        </>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.hasFranchiseAgreement === false &&
                            <li><Typography variant="body2">Did not submit proof of franchise</Typography></li>
                          }
                        </>
                        <>
                          {gasStation?.locationInfoReviewResult?.checks?.isFormAndDocumentInfoMatch === false &&
                            <li><Typography variant="body2">Submitted info and documents do not match</Typography></li>
                          }
                        </>
                      </ul>
                    </Box>
                    <>
                      {gasStation?.locationInfoReviewResult?.notes &&
                        <Box>
                          <Typography variant="body2">Reviewer Notes:</Typography>
                          <Typography>{`"${gasStation?.locationInfoReviewResult?.notes}"`}</Typography>
                        </Box>
                      }
                    </>
                  </Alert>
                </Box>
              }
            </>
          </Box>
          <TabsContainer>
            <Tabs value={activeTab} onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
              {
                tabList.map((e, i) => {
                  return <Tab key={i} label={e} />
                })
              }
            </Tabs>
          </TabsContainer>
          <Box>
            <>
              {tabList[activeTab] === 'Products' && showTabs.products &&
                <GasStationProducts gasStationId={gasStation.uuid} gasStation={gasStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Engagement' && showTabs.engagement &&
                <GasStationEngagement gasStation={gasStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Operations' && showTabs.operations &&
                <GasStationOperations gasStation={gasStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Business Info' && showTabs.businessInfo &&
                <GasStationLocation type="UPDATE" gasStation={gasStation} />
              }
            </>
          </Box>
          <Box>
            <>
              {tabList[activeTab] === 'Settings' && showTabs.settings &&
                <GasStationSettings gasStation={gasStation} />
              }
            </>
          </Box>
        </Box>
      }
    </>
  </Box>
}
