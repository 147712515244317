import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import Logger from '../services/logger'
import * as Types from '../types'
import useCoreApi from '../hooks/useCoreApi'

type GasStationEngagementProps = {
  gasStation?: Types.GasStation
}

type GasStationInteractionProps = {
  total?: number
}

type GetSearchImpressionsResponse = {
  data: {
    result?: Types.SearchImpression[]
  }
}

type GasStationInteractionResponse = {
  data: {
    result?: Types.GasStationInteraction[]
  }
}

type SearchImpressionData = {
  total: number
  today: number
  yesterday: number
}

type InteractionData = {
  total: number
  today: number
  yesterday: number
}

type DayDataProps = {
  favorite?: number
  unfavorite?: number
  like?: number
  dislike?: number
}

type StyledTheme = {
  theme: Theme,
}

const logger = new Logger({
  filePath: '@/components/GasStationEngagement'
})

const GasStationContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      margin: ${theme.spacing(3)};
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`

const EngagementCard = styled(Card)`
  ${({ theme }: StyledTheme) => `
    &.MuiCard-root {
      min-height: 100%;
      min-width: 100%;
    }
  `}
`

const getTotalHourlyCount = (searchImpression: Types.SearchImpression) => {
  const hourKeys: Types.SearchImpressionHourKey[] = ['h0', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'h9', 'h10', 'h11', 'h12', 'h13', 'h14', 'h15', 'h16', 'h17', 'h18', 'h19', 'h20', 'h21', 'h22', 'h23']

  let totalSearchImpressions = 0
  hourKeys.forEach((hourKey) => {
    totalSearchImpressions += searchImpression[hourKey]
  })

  return totalSearchImpressions
}

function EngagementStars(props: GasStationInteractionProps) {
  const totalInteraction = props.total
  const { gasStationId } = useParams()
  const [favoriteData, setFavoriteData] = useState<InteractionData>()
  const [unfavoriteData, setUnfavoriteData] = useState<InteractionData>()
  const { getCoreApiClient } = useCoreApi()

  function DayData ({ favorite, unfavorite }: DayDataProps) {
    if (favorite === undefined || unfavorite === undefined) {
      return <Typography variant="h4" component="p">-</Typography>
    }

    const value = favorite - unfavorite
    let color = 'GrayText'
    if (value < 0) {
      color = 'error'
    }

    if (value > 0) {
      color = 'green'
    }

    return <Typography variant="h4" component="p" color={color}>{value}</Typography>
  }

  useEffect(() => {
    let isSubscribed = true
    const ini = async () => {
      const coreApi = await getCoreApiClient()

      const [gasStationLikesResponse, gasStationDislikesResponse] = await Promise.all([
        coreApi.get(`/insights/gas-stations/${gasStationId}/interactions/FAVORITE`, {
          params: {
            range: 7
          },
        }),
        coreApi.get(`/insights/gas-stations/${gasStationId}/interactions/UNFAVORITE`, {
          params: {
            range: 7
          },
        })
      ]) as [GasStationInteractionResponse, GasStationInteractionResponse]


      if (!isSubscribed) {
        return
      }

      if (gasStationLikesResponse.data.result) {
        let total = 0
        gasStationLikesResponse.data.result.forEach((searchImpression) => {
          total += getTotalHourlyCount(searchImpression)
        })

        const sortedResult = [...gasStationLikesResponse.data.result]
        sortedResult.sort((a, b) => {
          if (a.date < b.date) {
            return 1
          }
          if (a.date > b.date) {
            return -1
          }
          return 0
        })

        const today = getTotalHourlyCount(sortedResult[0])
        const yesterday = getTotalHourlyCount(sortedResult[1])

        setFavoriteData({
          total,
          today,
          yesterday
        })
      }

      if (gasStationDislikesResponse.data.result) {
        let total = 0
        gasStationDislikesResponse.data.result.forEach((searchImpression) => {
          total += getTotalHourlyCount(searchImpression)
        })

        const sortedResult = [...gasStationDislikesResponse.data.result]
        sortedResult.sort((a, b) => {
          if (a.date < b.date) {
            return 1
          }
          if (a.date > b.date) {
            return -1
          }
          return 0
        })

        const today = getTotalHourlyCount(sortedResult[0])
        const yesterday = getTotalHourlyCount(sortedResult[1])

        setUnfavoriteData({
          total,
          today,
          yesterday
        })
      }
    }

    if (!favoriteData && !unfavoriteData && gasStationId) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [favoriteData, unfavoriteData, getCoreApiClient, gasStationId])

  return <Box mb={2} mr={2} width={380}>
    <EngagementCard>
      <CardContent>
        <Box mb={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" component="p">Bookmarks</Typography>
            <Tooltip title="User bookmarks of your gas station location: bookmark vs unbookmarked" arrow>
              <InfoOutlinedIcon color="action" />
            </Tooltip>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" spacing={3}>
            <Box mr={2}>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Total</Typography>
                <Typography variant="h2" component="p" color="green">{totalInteraction || '-'}</Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Today</Typography>
                <DayData favorite={favoriteData?.today} unfavorite={unfavoriteData?.today} />
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Yesterday</Typography>
                <DayData favorite={favoriteData?.yesterday} unfavorite={unfavoriteData?.yesterday} />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </EngagementCard>
  </Box>
}

function EngagementRatings(props: GasStationInteractionProps) {
  const totalInteraction = props.total
  const { gasStationId } = useParams()
  const [likesData, setLikesData] = useState<InteractionData>()
  const [dislikesData, setDislikesData] = useState<InteractionData>()
  const { getCoreApiClient } = useCoreApi()

  function DayData ({ like, dislike }: DayDataProps) {
    if (like === undefined || dislike === undefined) {
      return <Typography variant="h4" component="p">-</Typography>
    }

    return <Stack direction="row" spacing={1}>
      <Typography variant="h4" component="p" color="green">{like}</Typography>
      <Typography variant="h4" component="p">/</Typography>
      <Typography variant="h4" component="p" color="error">{dislike > 0 ? `-${dislike}` : 0}</Typography>
    </Stack>
  }

  useEffect(() => {
    let isSubscribed = true
    const ini = async () => {
      const coreApi = await getCoreApiClient()

      const [gasStationLikesResponse, gasStationDislikesResponse] = await Promise.all([
        coreApi.get(`/insights/gas-stations/${gasStationId}/interactions/LIKE`, {
          params: {
            range: 7
          },
        }),
        coreApi.get(`/insights/gas-stations/${gasStationId}/interactions/DISLIKE`, {
          params: {
            range: 7
          },
        })
      ]) as [GasStationInteractionResponse, GasStationInteractionResponse]


      if (!isSubscribed) {
        return
      }

      if (gasStationLikesResponse.data.result) {
        let total = 0
        gasStationLikesResponse.data.result.forEach((searchImpression) => {
          total += getTotalHourlyCount(searchImpression)
        })

        const sortedResult = [...gasStationLikesResponse.data.result]
        sortedResult.sort((a, b) => {
          if (a.date < b.date) {
            return 1
          }
          if (a.date > b.date) {
            return -1
          }
          return 0
        })

        const today = getTotalHourlyCount(sortedResult[0])
        const yesterday = getTotalHourlyCount(sortedResult[1])

        setLikesData({
          total,
          today,
          yesterday
        })
      }

      if (gasStationDislikesResponse.data.result) {
        let total = 0
        gasStationDislikesResponse.data.result.forEach((searchImpression) => {
          total += getTotalHourlyCount(searchImpression)
        })

        const sortedResult = [...gasStationDislikesResponse.data.result]
        sortedResult.sort((a, b) => {
          if (a.date < b.date) {
            return 1
          }
          if (a.date > b.date) {
            return -1
          }
          return 0
        })

        const today = getTotalHourlyCount(sortedResult[0])
        const yesterday = getTotalHourlyCount(sortedResult[1])

        console.log('###', total, today, yesterday)

        setDislikesData({
          total,
          today,
          yesterday
        })
      }
    }

    if (!likesData && !dislikesData && gasStationId) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [likesData, dislikesData, getCoreApiClient, gasStationId])

  return <Box mb={2} mr={2} width={380}>
    <EngagementCard>
      <CardContent>
        <Box mb={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" component="p">Ratings</Typography>
            <Tooltip title="User ratings of your gas station location: likes vs dislikes" arrow>
              <InfoOutlinedIcon color="action" />
            </Tooltip>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" spacing={3}>
            <Box mr={2}>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Total</Typography>
                <Typography variant="h2" component="p" color={totalInteraction && totalInteraction > 0 ? 'green' : 'error'}>{totalInteraction || '-'}</Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Today</Typography>
                <DayData like={likesData?.today} dislike={dislikesData?.today} />
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Yesterday</Typography>
                <DayData like={likesData?.yesterday} dislike={dislikesData?.yesterday} />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </EngagementCard>
  </Box>
}



function EngagementSearchImpression() {
  const { gasStationId } = useParams()
  const [data, setData] = useState<SearchImpressionData>()
  const { getCoreApiClient } = useCoreApi()

  useEffect(() => {
    let isSubscribed = true
    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getSearchImpressionsResponse = await coreApi.get(`/insights/gas-stations/${gasStationId}/search-impressions`, {
          params: {
            range: 7
          },
        }) as GetSearchImpressionsResponse
  
        if (!isSubscribed) {
          return
        }
  
        if (getSearchImpressionsResponse.data.result) {
          let total = 0
          getSearchImpressionsResponse.data.result.forEach((searchImpression) => {
            total += getTotalHourlyCount(searchImpression)
          })
  
          const sortedResult = [...getSearchImpressionsResponse.data.result]
          sortedResult.sort((a, b) => {
            if (a.date < b.date) {
              return 1
            }
            if (a.date > b.date) {
              return -1
            }
            return 0
          })
  
          const today = getTotalHourlyCount(sortedResult[0])
          const yesterday = getTotalHourlyCount(sortedResult[1])
  
          setData({
            total,
            today,
            yesterday
          })
        }
      } catch (err) {
        logger.error('EngagementSearchImpression failed to initialize', { err })
      }
    }

    if (!data && gasStationId) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, getCoreApiClient, gasStationId])

  return <Box mb={2} mr={2} width={380}>
    <EngagementCard>
      <CardContent>
        <Box mb={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" component="p">Search Impressions</Typography>
            <Tooltip title="Number of times your gas station appeared in search results. Data is based on 7-day period." arrow>
              <InfoOutlinedIcon color="action" />
            </Tooltip>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" spacing={3}>
            <Box mr={2}>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Total (7-day period)</Typography>
                <Typography variant="h2" component="p">{data?.total !== undefined ? data.total : '-'}</Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Today</Typography>
                <Typography variant="h4" component="p">{data?.today !== undefined ? data.today : '-'}</Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction="column">
                <Typography variant="body1" component="p" color="GrayText">Yesterday</Typography>
                <Typography variant="h4" component="p">{data?.yesterday !== undefined ? data.yesterday : '-'}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </EngagementCard>
  </Box>
}

export default function GasStationEngagement(props: GasStationEngagementProps) {
  const { gasStation } = props
  return <Box>
    <Box p={3}>
      <Typography variant="h5">User Engagement</Typography>
      <Typography color="#888888">Find out how your gas station location performs with Fewlsy users</Typography>
    </Box>
    <GasStationContainer>
      <EngagementSearchImpression />
      <EngagementRatings total={gasStation?.totalLikes || 0} />
      <EngagementStars total={gasStation?.totalFavorites || 0} />
    </GasStationContainer>
  </Box>
}
