import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import errcode from 'err-code'
import { useSnackbar } from 'notistack'

import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'

import SendIcon from '@mui/icons-material/Send'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import * as Types from '../types'

const logger = new Logger({
  filePath: '@/components/SettingsUsers'
})

type StyledTheme = {
  theme: Theme,
}

type CreateBusinessUserInviteResponse = {
  data: {
    result?: Types.BusinessUserInvite
  }
}

type DeleteBusinessUserInviteResponse = {
  data: {
    result?: string
  }
}

type DeleteBusinessUserResponse = {
  data: {
    result?: string
  }
}

type GetBusinessUserInvitesResponse = {
  data: {
    result?: {
      businessUserInvites: Types.BusinessUserInvite[]
    }
  }
}

type GetBusinessUsersResponse = {
  data: {
    result?: {
      users: Types.UserApiResponse[]
    }
  }
}

type FormErrors = {
  email?: 'FIELD_REQUIRED'
  submit?: 'REQUEST_FAILED' | 'EMAIL_ALREADY_LINKED_ERROR'
}

const SettingsUsersContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

function SettingsUsersList() {
  const { businessAccountId } = useParams()
  const { getCoreApiClient } = useCoreApi()
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState<Types.UserApiResponse[]>()
  const [isDeletingBusinessUser, setIsDeletingBusinessUser] = useState<string[]>([])

  const deleteBusinessUser = async (userId: string) => {
    try {
      const newIsDeletingBusinessUser = [
        ...isDeletingBusinessUser,
        userId,
      ]
      setIsDeletingBusinessUser(newIsDeletingBusinessUser)

      const coreApi = await getCoreApiClient()
      const deleteBusinessUserResponse = await coreApi.delete(`/business/${businessAccountId}/users/${userId}`) as DeleteBusinessUserResponse
  
      if (!deleteBusinessUserResponse?.data?.result) {
        throw errcode(new Error('Failed to delete business user invite'), 'InvalidResponse')
      }
  
      setIsDeletingBusinessUser(isDeletingBusinessUser.filter(i => i !== userId))
      enqueueSnackbar('User deleted', { variant: 'info' })
    } catch (err) {
      logger.log('deleteBusinessUser failed', { err })
      enqueueSnackbar('Failed to delete user', { variant: 'error' })
    } finally {
      const newUserList = data?.filter(d => d.uuid !== userId)
      setData(newUserList)
    }
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getBusinessUsersResponse = await coreApi.get(`/business/${businessAccountId}/users`) as GetBusinessUsersResponse

        if (!isSubscribed) {
          return
        }

        if (!getBusinessUsersResponse.data?.result?.users) {
          throw errcode(new Error('Failed to get business users'), 'GetBusinessUsersError')
        }

        const { users } = getBusinessUsersResponse.data.result
        users.sort((a, b) => {
          if (a.createdAt! < b.createdAt!) {
            return 1
          }
          if (a.createdAt! > b.createdAt!) {
            return -1
          }
          return 0
        })

        setData([...users])
      } catch (err: any) {
        const errCode = err.response?.data?.error?.code || err.code
        const errMessage = err.response?.data?.error?.message || err.message
        logger.error('Get business users failed', { errMessage, errCode })
      }
    }

    if (!data) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, businessAccountId, getCoreApiClient])

  if (!data) {
    return <Box mt={3}>
      <Skeleton variant="rounded" width={600} height={276} />
    </Box>
  }

  return <Box mt={3}>
    <Box mb={2}>
      <Typography variant="h6" component="h2">User List</Typography>
    </Box>
    <Box maxWidth="md">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Display Name</b></TableCell>
              <TableCell align="center"><b>Admin</b></TableCell>
              <TableCell align="right"><b>Email</b></TableCell>
              <TableCell align="right"><b>Last Seen</b></TableCell>
              <TableCell align="right"><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((u) => {
              return <TableRow key={u.uuid}>
                <TableCell component="th" scope="row">
                  <Typography>{u.displayName}</Typography>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <>
                    {u.businessAccount?.isAdmin &&
                      <CheckIcon color="primary" />
                    }
                  </>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <Typography variant="body2">{u.email}</Typography>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <Typography variant="body2">-</Typography>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {/* <IconButton><EditIcon /></IconButton> */}
                    <>
                      {!u.businessAccount?.isAdmin &&
                        <IconButton disabled={isDeletingBusinessUser.includes(u.uuid)} onClick={() => deleteBusinessUser(u.uuid)}><DeleteIcon /></IconButton>
                      }
                    </>
                  </Stack>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Box>
}

type SettingsUsersInviteListProps = {
  lastUpdated: number
}

function SettingsUsersInviteList(props: SettingsUsersInviteListProps) {
  const { lastUpdated } = props

  const { businessAccountId } = useParams()
  const { getCoreApiClient } = useCoreApi()
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState<Types.BusinessUserInvite[]>()
  const [lastFetched, setLastFetched] = useState<number>()
  const [isSendingInvite, setIsSendingInvite] = useState<string[]>([])
  const [isDeletingInvite, setIsDeletingInvite] = useState<string[]>([])

  const allowedElapsedTimeForInviteResend = 60000

  const sendInvite = async (email: string) => {
    try {
      const newIsSendingInvite = [
        ...isSendingInvite,
        email,
      ]
      setIsSendingInvite(newIsSendingInvite)

      const coreApi = await getCoreApiClient()
      const createBusinessUserInviteResponse = await coreApi.post(`/business/${businessAccountId}/invites`, {
        email,
      }) as CreateBusinessUserInviteResponse
  
      if (!createBusinessUserInviteResponse?.data?.result) {
        throw errcode(new Error('Failed to create business user invite'), 'InvalidResponse')
      }
  
      setIsSendingInvite(isSendingInvite.filter(e => e !== email))

      enqueueSnackbar('Sent invitation to user email', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('Failed to send invitation', { variant: 'error' })
    } finally {
      const newPendingUserInfo = data?.map(i => {
        if (i.email === email) {
          return {
            ...i,
            updatedAt: new Date(),
          }
        }

        return i
      })

      setData(newPendingUserInfo)
    }
  }

  const deleteInvite = async (businessUserInviteId: string) => {
    try {
      const newIsDeletingInvite = [
        ...isDeletingInvite,
        businessUserInviteId,
      ]
      setIsDeletingInvite(newIsDeletingInvite)

      const coreApi = await getCoreApiClient()
      const deleteBusinessUserInviteResponse = await coreApi.delete(`/business/${businessAccountId}/invites/${businessUserInviteId}`) as DeleteBusinessUserInviteResponse
  
      if (!deleteBusinessUserInviteResponse?.data?.result) {
        throw errcode(new Error('Failed to delete business user invite'), 'InvalidResponse')
      }
  
      setIsSendingInvite(isSendingInvite.filter(i => i !== businessUserInviteId))
      enqueueSnackbar('Invitation deleted', { variant: 'info' })
    } catch (err) {
      enqueueSnackbar('Failed to delete invitation', { variant: 'error' })
    } finally {
      const newPendingUserInfo = data?.filter(d => d.uuid !== businessUserInviteId)
      setData(newPendingUserInfo)
    }
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getBusinessUserInvitesResponse = await coreApi.get(`/business/${businessAccountId}/invites`) as GetBusinessUserInvitesResponse

        if (!isSubscribed) {
          return
        }

        if (!getBusinessUserInvitesResponse.data?.result?.businessUserInvites) {
          throw errcode(new Error('Failed to get business user invites'), 'GetBusinessUserInvitesError')
        }

        const { businessUserInvites } = getBusinessUserInvitesResponse.data.result
        businessUserInvites.sort((a, b) => {
          if (a.updatedAt! < b.updatedAt!) {
            return 1
          }
          if (a.updatedAt! > b.updatedAt!) {
            return -1
          }
          return 0
        })

        setData(businessUserInvites.filter(i => i.isUsed === false))
        setLastFetched(Date.now())
      } catch (err: any) {
        const errCode = err.response?.data?.error?.code || err.code
        const errMessage = err.response?.data?.error?.message || err.message
        logger.error('Get business user invites failed', { errMessage, errCode })
      }
    }

    if (!data || !lastFetched || (lastFetched < lastUpdated)) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [lastFetched, lastUpdated, businessAccountId, data, getCoreApiClient])

  return <Box mt={3}>
    <>
      {!data &&
        <Box mt={3}>
          <Skeleton variant="rounded" width={600} height={276} />
        </Box>
      }
    </>
    <>
      {data && data.length > 0 &&
        <Box mt={3}>
          <Box mb={2}>
            <Typography variant="h6" component="h2">Pending Invites</Typography>
          </Box>
          <Box maxWidth="sm">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell align="right"><b>Last Update</b></TableCell>
                    <TableCell align="right"><b>Actions</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((i) => {
                    return <TableRow key={i.uuid}>
                      <TableCell component="th" scope="row">
                        <Typography>{i.email}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        <Typography>{new Date(i.updatedAt).toLocaleDateString()}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                          <>
                            {(Date.now() - new Date(i.updatedAt).getTime()) >= allowedElapsedTimeForInviteResend && // 1m before you can resend
                              <IconButton disabled={isSendingInvite.includes(i.email)} onClick={() => sendInvite(i.email)}><SendIcon /></IconButton>
                            }
                          </>
                          <>
                            {(Date.now() - new Date(i.updatedAt).getTime()) < allowedElapsedTimeForInviteResend &&
                              <Tooltip title="An invite was recently sent" arrow>
                                <Box>
                                  <IconButton disabled><SendIcon /></IconButton>
                                </Box>
                              </Tooltip>
                            }
                          </>
                          <IconButton disabled={isDeletingInvite.includes(i.uuid)} onClick={() => deleteInvite(i.uuid)}><DeleteIcon /></IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      }
    </>
  </Box>
  
}

export default function SettingsUsers() {
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const emailRef = useRef<TextFieldProps>(null)

  const [inviteListLastUpdated, setInviteListLastUpdated] = useState(Date.now())

  const { businessAccountId } = useParams()
  const { getCoreApiClient } = useCoreApi()
  const { enqueueSnackbar } = useSnackbar()

  const inviteUser = async () => {
    try {
      setIsFormDisabled(true)

      if (!emailRef.current) {
        logger.error('Reference to form field is missing', {
          refs: [
            emailRef.current,
          ]
        })
        throw new Error('Reference to form field is missing')
      }


      const email = emailRef.current.value as string

      let formErrorsFound: FormErrors = {}

      if (email.trim().length < 1) {
        formErrorsFound.email = 'FIELD_REQUIRED'          
      }

      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      // No errors found
      setFormErrors({})

      const coreApi = await getCoreApiClient()
      const createBusinessUserInviteResponse = await coreApi.post(`/business/${businessAccountId}/invites`, {
        email,
      }) as CreateBusinessUserInviteResponse

      if (!createBusinessUserInviteResponse?.data?.result) {
        throw errcode(new Error('Failed to create business user invite'), 'InvalidResponse')
      }

      setIsFormDisabled(false)
      enqueueSnackbar('Sent invitation to user email', { variant: 'success' })
      emailRef.current.value = ''
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Invite user failed', { errMessage, errCode })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      } else if (errCode === 'EmailAlreadyLinkedError') {
        setFormErrors({ submit: 'EMAIL_ALREADY_LINKED_ERROR' })
        setIsFormDisabled(false)
        return

      }

      formErrorsFound.submit = 'REQUEST_FAILED'

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    } finally {
      setInviteListLastUpdated(Date.now())
    }
  }

  const handleOnInviteUserClick = () => {
    inviteUser()
  }

  return <SettingsUsersContainer>
    <Box>
      <FormControl error={formErrors.email !== undefined || formErrors.submit !== undefined}>
        <Box mb={2}>
          <FormLabel>Invite a user to your business account</FormLabel>
        </Box>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <TextField
            size="small"
            label="Email Address"
            variant="outlined"
            disabled={isFormDisabled}
            inputRef={emailRef}
            error={formErrors.email === 'FIELD_REQUIRED' || formErrors.submit === 'EMAIL_ALREADY_LINKED_ERROR'}
            helperText={formErrors.email === 'FIELD_REQUIRED' ? 'Field required' : undefined}
          />
          <Button
            variant="contained"
            disabled={isFormDisabled}
            onClick={handleOnInviteUserClick}
          >Invite User</Button>
        </Stack>
        <>
          {formErrors.submit === 'REQUEST_FAILED' &&
            <FormHelperText>Failed to invite user</FormHelperText>
          }
        </>
        <>
          {formErrors.submit === 'EMAIL_ALREADY_LINKED_ERROR' &&
            <FormHelperText>Email address already linked to a business account</FormHelperText>
          }
        </>
      </FormControl>
    </Box>
    <SettingsUsersInviteList lastUpdated={inviteListLastUpdated} />
    <SettingsUsersList />
  </SettingsUsersContainer>
}
