import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import usePageRoutes from '../hooks/usePageRoutes'
import Logger from '../services/logger'

const logger = new Logger({
  filePath: '@/screens/MapToBusiness'
})

export default function MapToBusiness () {
  const { currentUser } = useUser()
  const navigate = useNavigate()
  const pageRoutes = usePageRoutes()

  const [hasInitialized, setHasInitialized] = useState(false)

  useEffect(() => {
    let isSubscribed = true
    const effect = () => {
      if (!isSubscribed) {
        return false
      }

      if (!currentUser || !currentUser.businessAccount || currentUser.businessAccount.businessAccountId.length < 1) {
        logger.error('Current user not found')

        const privatePages = pageRoutes.getPrivatePages('undefined')
        navigate(privatePages.home, { replace: true })

        setHasInitialized(true)

        return
      }

      const { businessAccount } = currentUser
      const privatePages = pageRoutes.getPrivatePages(businessAccount.businessAccountId)
      navigate(privatePages.home, { replace: true })

      setHasInitialized(true)
    }

    if (!hasInitialized) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [currentUser, pageRoutes, navigate, hasInitialized, setHasInitialized])

  return <></>
}
