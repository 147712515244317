import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import styled from 'styled-components'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import DashboardContent from './DashboardContent'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import useGasStationBrand from '../hooks/useGasStationBrand'
import * as Types from '../types'
import usePageRoutes from '../hooks/usePageRoutes'
import useUser from '../hooks/useUser'

import imgAppIconMuted from '../static/images/app-icon-muted.svg'

export type GetGasStationsResponse = {
  data: {
    result?: Types.GasStation[]
  }
}

type StyledTheme = {
  theme: Theme,
}

const logger = new Logger({
  filePath: '@/components/DashboardGasStations'
})

const NoLocationsBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`

const GasStationCard = styled(Card)<CardProps>(({ theme }) => ({
  marginRight: 0,
  marginBottom: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
    width: '300px'
  },
}))

const GasStationCardContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`

const GasStationCardSkeleton = styled(Skeleton)`
  ${({ theme }: StyledTheme) => `
    &.MuiSkeleton-root {
      margin-right: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`

function NoGasStations() {
  const pageRoutes = usePageRoutes()
  const { businessAccountId } = useParams()

  const privatePages = pageRoutes.getPrivatePages(businessAccountId)

  return <Container maxWidth={false}>
    <NoLocationsBox py={3}>
      <Box>
        <Stack direction="column" spacing={5} alignItems="center">
          <Box textAlign="center">
            <img src={imgAppIconMuted} width={128} height={128} alt="No gas station locations" />
          </Box>
          <Box textAlign="center">
            <Typography variant="h5" color="#333333">Your dashboard is a bit empty right now.</Typography>
            <Typography variant="h6" color="#333333">Add your first gas station location.</Typography>
          </Box>
          <Box textAlign="center">
            <Button variant="contained" component={Link as any} to={`${privatePages.addGasStationLocation}`}>Add Location</Button>
          </Box>
        </Stack>
      </Box>
    </NoLocationsBox>
  </Container>
}

export default function DashboardGasStations() {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { getBrandByCode } = useGasStationBrand()
  const { getCoreApiClient } = useCoreApi()
  const [gasStations, setGasStations] = useState<Types.GasStation[]>()
  const pageRoutes = usePageRoutes()
  const { businessAccountId } = useParams()
  const { currentUser } = useUser()

  const privatePages = pageRoutes.getPrivatePages(businessAccountId)

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getGasStationResponse = await coreApi.get(`/business/${businessAccountId}/gas-stations`) as GetGasStationsResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getGasStationResponse.data.result) {
          setGasStations([...getGasStationResponse.data.result])
        }
      } catch (err) {
        logger.error('DashboardGasStations failed to initialize', { err })
      }
    }

    if (!gasStations) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [gasStations, businessAccountId, getCoreApiClient])

  const currentUserPermissions: string[] = []
  if (currentUser?.businessAccount?.permissions) {
    currentUser.businessAccount.permissions.forEach(p => {
      if (p.type === 'gas-station-permission') {
        currentUserPermissions.push(p.gasStationId)
      }
    })
  }

  if (gasStations && gasStations.length <= 0) {
    return <DashboardContent>
      <NoGasStations />
    </DashboardContent>
  }

  return <DashboardContent>
    <>
      {!gasStations &&
        <LinearProgress />
      }
    </>
    <Container maxWidth={false}>
      <Stack direction="column">
        <Box py={3}>
          <Stack direction="column" spacing={3}>
            <Box>
              <Stack direction={smUp ? 'row' : 'column'} spacing={smUp ? 3 : 1}>
                <Typography variant="h5" fontWeight="bold">Gas Stations</Typography>
                <>
                  {currentUser?.businessAccount?.isAdmin &&
                    <Button variant="contained" component={Link as any} to={`${privatePages.addGasStationLocation}`}>Add Location</Button>
                  }
                </>
              </Stack>
            </Box>
            <Box>
              <GasStationCardContainer>
                <>
                  {!gasStations &&
                    <>
                      <GasStationCardSkeleton variant="rounded" width={300} height={217} />
                      <GasStationCardSkeleton variant="rounded" width={300} height={217} />
                      <GasStationCardSkeleton variant="rounded" width={300} height={217} />
                    </>
                  }
                </>
                <>
                  {gasStations && gasStations.length > 0 &&
                    gasStations.filter(gasStation => ['PENDING_REVIEW', 'ACTIVE', 'REJECTED'].includes(gasStation.status)).map((gasStation) => {
                      let isUserAllowedAccess = true
                      if (!currentUser?.businessAccount?.isAdmin && !currentUserPermissions.includes(gasStation.uuid)) {
                        isUserAllowedAccess = false
                      }

                      return <GasStationCard key={gasStation.uuid}>
                        <CardContent style={{ flexGrow: 1 }}>
                          <Box mb={1}>
                            <>
                              {gasStation.status === 'REJECTED' &&
                                <Tooltip title="This gas station location has been rejected." arrow>
                                  <Chip label="Rejected" color="error" size="small" />
                                </Tooltip>
                              }
                            </>
                            <>
                              {gasStation.status === 'PENDING_REVIEW' &&
                                <Tooltip title="This gas station location is still in review. Review process can take up to 3 or more business days." arrow>
                                  <Chip label="Pending Review" color="warning" size="small" />
                                </Tooltip>
                              }
                            </>
                            <>
                              {gasStation.status === 'ACTIVE' &&
                                <Chip label="Active" color="info" size="small" />
                              }
                            </>
                          </Box>
                          <Typography variant="h5">{gasStation.label}</Typography>
                          <Typography variant="body1">{gasStation.businessName}</Typography>
                          <Typography variant="body1">{getBrandByCode(gasStation.brand).name}</Typography>
                          <Typography variant="body2" color="GrayText">{`${gasStation.addressStreet}, ${gasStation.addressBarangay}, ${gasStation.addressCity}`}</Typography>
                        </CardContent>
                        <CardActions>
                          <>
                            {isUserAllowedAccess &&
                              <Button variant="text" component={Link as any} to={`${privatePages.gasStations}/${gasStation.uuid}`}>Open</Button>
                            }
                          </>
                          <Button variant="text" href={`https://maps.google.com/?q=${gasStation.addressLatitude},${gasStation.addressLongitude}`} target="_blank" rel="noreferrer">Map</Button>
                        </CardActions>
                      </GasStationCard>
                    })
                  }
                </>
              </GasStationCardContainer>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Container>
  </DashboardContent>
}
