import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

import Error40X from './Error40X'
import SettingsUsers from './SettingsUsers'
import useUser from '../hooks/useUser'

type StyledTheme = {
  theme: Theme,
}

const TabsContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `}
`

export default function DashboardSettings() {
  const [activeTab, setActiveTab] = useState(0)
  const { currentUser } = useUser()

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  if (currentUser?.businessAccount?.isAdmin === false) {
    return <Error40X />
  }

  return <Box>
    <Box p={3}>
      <Typography variant="h5" fontWeight="bold">Business Account Settings</Typography>
    </Box>
    <TabsContainer>
      <Tabs value={activeTab} onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
        <Tab label="Users" />
      </Tabs>
    </TabsContainer>
    <Box>
      <Box hidden={activeTab !== 0}>
        <>
          {activeTab === 0 &&
            <SettingsUsers />
          }
        </>
      </Box>
    </Box>
  </Box>
}
