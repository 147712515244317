export default function usePageRoutes() {
  const publicPages = {
    login: '/login',
    register: '/register',
  }

  const privatePages = {
    home: '/',
    gasStations: '/gas-stations',
    insights: '/insights',
    settings: '/settings',
  }

  const getGasStationPages = (gasStationUuid: string) => {
    return {
      main: `${privatePages.gasStations}/${gasStationUuid}`
    }
  }

  const getPrivatePages = (businessAccountId: string = 'undefined') => {
    return {
      home: `/business/${businessAccountId}`,
      gasStations: `/business/${businessAccountId}/gas-stations`,
      insights: `/business/${businessAccountId}/insights`,
      settings: `/business/${businessAccountId}/settings`,
      addGasStationLocation: `/business/${businessAccountId}/gas-stations/add-location`
    }
  }

  return {
    getPrivatePages,
    privatePage: {
      ...privatePages,
      getGasStationPages
    },
    publicPage: {
      ...publicPages,
    }
  }
}
