import PH_GAS_STATION_BRANDS from '../data/ph-gas-stations.json'

export const useGasStationBrand = () => {
  const getBrandByCode = (code: string) => {
    return PH_GAS_STATION_BRANDS.filter(brands => brands.code === code)[0]
  }

  return {
    getBrandByCode,
  }
}

export default useGasStationBrand
