import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import errcode from 'err-code'
import { useSnackbar } from 'notistack'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Skeleton from '@mui/material/Skeleton'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import Chip from '@mui/material/Chip'

import EditIcon from '@mui/icons-material/Edit'

import styled from 'styled-components'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import useCoreApi from '../hooks/useCoreApi'
import * as Types from '../types'

const logger = new Logger({
  filePath: '@/components/GasStationSettings'
})

type GasStationSettingsProps = {
  gasStation?: Types.GasStation
}

type StyledTheme = {
  theme: Theme
}

type GetBusinessUsersResponse = {
  data: {
    result?: {
      users: Types.UserApiResponse[]
    }
  }
}

type UserPermissionsDialogProps = {
  user: Types.UserApiResponse
  permissions: Types.UserGasStationPermissions | null
  open: boolean
  onClose: (userPermissionsUpdated?: boolean) => void
}

type UserPermissionsDialogFormErrors = {
  submit?: 'REQUEST_FAILED'
}

type PutBusinessUserPermissionsResponse = {
  data: {
    result?: Types.UserApiResponse
  }
}

type PutBusinessUserPermissionsRequest = {
  permissions: {
    type: 'gas-station-permission'
    gasStationId: string
    products: 'VIEWER' | 'EDITOR' | null
    engagement: 'VIEWER' | null
    operations: 'EDITOR' | null
    businessInfo: 'EDITOR' | null
  }
}

type GasStationPermissionsSwitches = {
  productsViewer: boolean
  productsEditor: boolean
  engagementViewer: boolean
  operationsEditor: boolean
  businessInfoEditor: boolean
}

const GasStationContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const StyledTableRow = styled(TableRow)`
  ${({ theme }: StyledTheme) => `
    &.MuiTableRow-root .MuiTableCell-root:nth-child(even) {
      background-color: #f4f4f4;
    }
  `}
`

function UserPermissionsDialog(props: UserPermissionsDialogProps) {
  const { open, onClose, user, permissions } = props

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const { getCoreApiClient } = useCoreApi()
  const { businessAccountId, gasStationId } = useParams()

  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [formErrors, setFormErrors] = useState<UserPermissionsDialogFormErrors>({})

  const [switches, setSwitches] = useState<GasStationPermissionsSwitches>({
    productsViewer: false,
    productsEditor: false,
    engagementViewer: false,
    operationsEditor: false,
    businessInfoEditor: false,
  })

  const handleOnClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!isFormDisabled) {
      if (reason !== 'backdropClick') {
        onClose()
      }
    }
  }

  const handleOnCancelClick = () => {
    onClose()
  }

  const handleOnSaveClick = () => {
    savePermissions()
  }

  const savePermissions = async () => {
    try {
      setIsFormDisabled(true)

      if (!gasStationId) {
        throw errcode(new Error('Missing gasStationId for put business user permissions payload'), 'FormSubmitError')
      }

      const requestPayload: PutBusinessUserPermissionsRequest = {
        permissions: {
          type: 'gas-station-permission',
          gasStationId,
          businessInfo: switches.businessInfoEditor ? 'EDITOR' : null,
          engagement: switches.engagementViewer ? 'VIEWER' : null,
          operations: switches.operationsEditor ? 'EDITOR' : null,
          products: null,
        }
      }

      if (switches.productsViewer) {
        requestPayload.permissions.products = 'VIEWER'
      }

      if (switches.productsEditor) {
        requestPayload.permissions.products = 'EDITOR'
      }

      const coreApi = await getCoreApiClient()
      const putGasStationLocationInfoReviewResponse = await coreApi.put(`/business/${businessAccountId}/users/${user.uuid}/permissions`, requestPayload) as PutBusinessUserPermissionsResponse

      if (!putGasStationLocationInfoReviewResponse?.data?.result) {
        throw errcode(new Error('Failed to put business user permissions'), 'FormSubmitError')
      }

      onClose(true)
      setIsFormDisabled(false)
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Save permissions failed', { errMessage, errCode })

      const formErrorsFound: UserPermissionsDialogFormErrors = {
        submit: 'REQUEST_FAILED'
      }

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleSwitchChange = (key: keyof GasStationPermissionsSwitches, event: React.ChangeEvent<HTMLInputElement>) => {
    const newSwitches = {
      ...switches,
      [key]: event.target.checked
    }

    if (key === 'productsEditor' && event.target.checked === true) {
      newSwitches.productsViewer = false
    }

    if (key === 'productsViewer' && event.target.checked === true) {
      newSwitches.productsEditor = false
    }

    setSwitches({ ...newSwitches })
  }

  useEffect(() => {
    const newSwitches: GasStationPermissionsSwitches = {
      productsViewer: !!(permissions && permissions.products && permissions.products === 'VIEWER'),
      productsEditor: !!(permissions && permissions.products && permissions.products === 'EDITOR'),
      engagementViewer: !!(permissions && permissions.engagement && permissions.engagement === 'VIEWER'),
      operationsEditor: !!(permissions && permissions.operations && permissions.operations === 'EDITOR'),
      businessInfoEditor: !!(permissions && permissions.businessInfo && permissions.businessInfo === 'EDITOR'),
    }

    setSwitches(newSwitches)
  }, [permissions])

  return <Dialog
    open={open}
    onClose={handleOnClose}
    maxWidth="md"
  >
    <Box p={2} minWidth={mdUp ? 400 : 0}>
      <Box mb={2}>
        <Typography>Manage <b>{`${user.displayName}'s`}</b> Permissions</Typography>
      </Box>
      <Box mb={2}>
        <Stack direction="column">
          <Box>
            <Typography variant="body2">Products</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={<Switch
                checked={switches.productsViewer}
                onChange={(e) => handleSwitchChange('productsViewer', e)}
                disabled={isFormDisabled}
              />}
              label="Viewer"
            />
            <FormControlLabel
              control={<Switch
                checked={switches.productsEditor}
                onChange={(e) => handleSwitchChange('productsEditor', e)}
                disabled={isFormDisabled}
              />}
              label="Editor"
            />
          </Box>
        </Stack>
      </Box>
      <Box mb={2}>
        <Stack direction="column">
          <Box>
            <Typography variant="body2">Engagement</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={<Switch
                checked={switches.engagementViewer}
                onChange={(e) => handleSwitchChange('engagementViewer', e)}
                disabled={isFormDisabled}
              />}
              label="Viewer"
            />
          </Box>
        </Stack>
      </Box>
      <Box mb={2}>
        <Stack direction="column">
          <Box>
            <Typography variant="body2">Operations</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={<Switch
                checked={switches.operationsEditor}
                onChange={(e) => handleSwitchChange('operationsEditor', e)}
                disabled={isFormDisabled}
              />}
              label="Editor"
            />
          </Box>
        </Stack>
      </Box>
      <Box mb={2}>
        <Stack direction="column">
          <Box>
            <Typography variant="body2">Business Info</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={<Switch
                checked={switches.businessInfoEditor}
                onChange={(e) => handleSwitchChange('businessInfoEditor', e)}
                disabled={isFormDisabled}
              />}
              label="Editor"
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack direction="row" spacing={4} justifyContent="flex-end">
          <Button variant="contained" color="error" disabled={isFormDisabled} onClick={handleOnCancelClick}>Cancel</Button>
          <Button variant="text" color="primary" disabled={isFormDisabled} onClick={handleOnSaveClick}>{isFormDisabled ? 'Saving...' : 'Save'}</Button>
        </Stack>
        <>
          {formErrors.submit === 'REQUEST_FAILED' &&
            <Box>
              <Alert severity="error">Failed to update business user permissions</Alert>
            </Box>
          }
        </>
      </Box>
    </Box>
  </Dialog>
}

function SettingsUsersList() {
  const { businessAccountId, gasStationId } = useParams()
  const { getCoreApiClient } = useCoreApi()
  const { enqueueSnackbar } = useSnackbar()
  const pageRoutes = usePageRoutes()

  const [selectedUser, setSelectedUser] = useState<Types.UserApiResponse | null>(null)
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<Types.UserGasStationPermissions | null>(null)

  const [data, setData] = useState<Types.UserApiResponse[]>()

  const privatePages = pageRoutes.getPrivatePages(businessAccountId)

  const fetchBusinessUsersData = async () => {
    try {
      const coreApi = await getCoreApiClient()
      const getBusinessUsersResponse = await coreApi.get(`/business/${businessAccountId}/users`) as GetBusinessUsersResponse

      if (!getBusinessUsersResponse.data?.result?.users) {
        throw errcode(new Error('Failed to get business users'), 'GetBusinessUsersError')
      }

      const { users } = getBusinessUsersResponse.data.result
      users.sort((a, b) => {
        if (a.createdAt! < b.createdAt!) {
          return 1
        }
        if (a.createdAt! > b.createdAt!) {
          return -1
        }
        return 0
      })

      setData([...users])
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message
      logger.error('Get business users failed', { errMessage, errCode })
    }
  }

  const handleOnUserPermissionsDialogClose = (userPermissionsUpdated?: boolean) => {
    setSelectedUser(null)
    setSelectedUserPermissions(null)

    if (userPermissionsUpdated) {
      enqueueSnackbar('User permissions updated', { variant: 'success' })
      fetchBusinessUsersData()
    }
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getBusinessUsersResponse = await coreApi.get(`/business/${businessAccountId}/users`) as GetBusinessUsersResponse

        if (!isSubscribed) {
          return
        }

        if (!getBusinessUsersResponse.data?.result?.users) {
          throw errcode(new Error('Failed to get business users'), 'GetBusinessUsersError')
        }

        const { users } = getBusinessUsersResponse.data.result
        users.sort((a, b) => {
          if (a.createdAt! < b.createdAt!) {
            return 1
          }
          if (a.createdAt! > b.createdAt!) {
            return -1
          }
          return 0
        })

        setData([...users])
      } catch (err: any) {
        const errCode = err.response?.data?.error?.code || err.code
        const errMessage = err.response?.data?.error?.message || err.message
        logger.error('Get business users failed', { errMessage, errCode })
      }
    }

    if (!data) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, businessAccountId, getCoreApiClient])

  if (!data) {
    return <Box mb={3}>
      <Skeleton variant="rounded" width={600} height={276} />
    </Box>
  }

  const hasNonAdminUsers = data.filter(u => !u.businessAccount?.isAdmin).length > 0

  return <Box mb={5}>
    <Box mb={2}>
      <Typography variant="h6" component="h2">User Permissions</Typography>
      <Typography variant="body1" color="GrayText">Change the permissions these users can have for this gas station location</Typography>
    </Box>
    <Box>
      <>
        {!hasNonAdminUsers &&
          <Box maxWidth={350}>
            <Alert severity="info">
              <span>Your account does not have any other users.</span>
              <Box mt={1}><Button variant="outlined" color="secondary" component={Link} to={privatePages.settings}>Invite your team</Button></Box>
            </Alert>
          </Box>
        }
      </>
      <>
        {hasNonAdminUsers &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <TableCell><b>User</b></TableCell>
                  <TableCell align="center"><b>Products</b></TableCell>
                  <TableCell align="center"><b>Engagement</b></TableCell>
                  <TableCell align="center"><b>Operations</b></TableCell>
                  <TableCell align="center"><b>Business Info</b></TableCell>
                  <TableCell align="center"><b>Actions</b></TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.filter(u => !u.businessAccount?.isAdmin).map((u) => {
                  const permissions = u.businessAccount?.permissions?.filter(p => p.type === 'gas-station-permission' && p.gasStationId === gasStationId)[0]

                  if (permissions?.type === 'gas-station-permission') {
                    return <StyledTableRow key={u.uuid}>
                      <TableCell component="th" scope="row">
                        <Typography><b>{u.displayName}</b></Typography>
                        <Typography variant="body2" color="GrayText">{u.email}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <>
                          {permissions && permissions.products &&
                            <Chip label={permissions.products === 'VIEWER' ? 'Viewer' : 'Editor'} color={permissions.products === 'VIEWER' ? 'primary' : 'info'} />
                          }
                        </>
                      </TableCell>
                      <TableCell align="center">
                        <>
                          {permissions && permissions.engagement &&
                            <Chip label="Viewer" color="primary" />
                          }
                        </>
                      </TableCell>
                      <TableCell align="center">
                        <>
                          {permissions && permissions.operations &&
                            <Chip label="Editor" color="info" />
                          }
                        </>
                      </TableCell>
                      <TableCell align="center">
                      <>
                          {permissions && permissions.businessInfo &&
                            <Chip label="Editor" color="info" />
                          }
                        </>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Stack direction="row" spacing={1} justifyContent="center">
                          <IconButton onClick={() => {
                            setSelectedUser(u)
                            setSelectedUserPermissions(permissions)
                          }}><EditIcon /></IconButton>
                        </Stack>
                      </TableCell>
                    </StyledTableRow>
                  }

                  return <StyledTableRow key={u.uuid}>
                    <TableCell component="th" scope="row">
                      <Typography><b>{u.displayName}</b></Typography>
                      <Typography variant="body2" color="GrayText">{u.email}</Typography>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Stack direction="row" spacing={1} justifyContent="center">
                        <IconButton onClick={() => {
                          setSelectedUser(u)
                          setSelectedUserPermissions(null)
                        }}><EditIcon /></IconButton>
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>

    </Box>
    <>
      {selectedUser &&
        <UserPermissionsDialog open={true} onClose={handleOnUserPermissionsDialogClose} user={selectedUser} permissions={selectedUserPermissions}></UserPermissionsDialog>
      }
    </>
  </Box>
}

export default function GasStationSettings(props: GasStationSettingsProps) {
  const [data, setData] = useState<Types.UserApiResponse[]>() 
  
  const { businessAccountId } = useParams()
  const { getCoreApiClient } = useCoreApi()

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getBusinessUsersResponse = await coreApi.get(`/business/${businessAccountId}/users`) as GetBusinessUsersResponse

        if (!isSubscribed) {
          return
        }

        if (!getBusinessUsersResponse.data?.result?.users) {
          throw errcode(new Error('Failed to get business users'), 'GetBusinessUsersError')
        }

        const { users } = getBusinessUsersResponse.data.result
        users.sort((a, b) => {
          if (a.createdAt! < b.createdAt!) {
            return 1
          }
          if (a.createdAt! > b.createdAt!) {
            return -1
          }
          return 0
        })

        setData([...users])
      } catch (err: any) {
        const errCode = err.response?.data?.error?.code || err.code
        const errMessage = err.response?.data?.error?.message || err.message
        logger.error('Get business users failed', { errMessage, errCode })
      }
    }

    if (!data) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, businessAccountId, getCoreApiClient])

  return <GasStationContainer>
    <Box maxWidth="lg">
      <SettingsUsersList />
    </Box>
  </GasStationContainer>
}
