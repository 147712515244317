import React, { useState, useRef } from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import errcode from 'err-code'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import {
  Stack,
  Box,
  Button,
  Container,
  TextField,
  TextFieldProps,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Alert,
  AlertTitle,
} from '@mui/material'
import { Theme } from '@mui/material/styles'

import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import useCoreApi from '../hooks/useCoreApi'
import useUser from '../hooks/useUser'

import { UserApiResponse } from '../types'

import '../static/styles/main.css'
import imgAppIcon from '../static/images/app-icon.svg'
import imgGoogleIcon from '../static/images/google-icon.png'
import imgRegistrationFailed from '../static/images/registration-failed-graphic.jpg'

const logger = new Logger({
  filePath: '@/screens/AuthRegister'
})

type StyledTheme = {
  theme: Theme
}

type GoogleBtnProps = {
  children: React.ReactNode
}

type ProviderUserInfo = {
  uid: string
  email: string
  isEmailVerified: boolean
  displayName: string
}

type FormErrors = {
  operator?: 'FIELD_REQUIRED' | 'MUST_BE_GAS_STATION_OPERATOR'
  country?: 'FIELD_REQUIRED' | 'MUST_BE_OPERATING_IN_PHILIPPINES'
  totalStations?: 'FIELD_REQUIRED'
  submit?: 'USER_ALREADY_EXISTS' | 'REQUEST_FAILED'
}

export type CreateUserResponse = {
  data: {
    result?: UserApiResponse
  }
}

const AuthContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  `}
`

export default function RegisterScreen() {
  const navigate = useNavigate()
  const [providerUserInfo, setProviderUserInfo] = useState<ProviderUserInfo>()
  const [isProviderAuthenticating, setIsProviderAuthenticating] = useState(false)
  const [accountType, setAccountType] = useState<'BUSINESS' | 'PERSONAL'>()
  const [isGasStationOperator, setIsGasStationOperator] = useState(false)
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const [isEmailOptInAccepted, setIsEmailOptInAccepted] = useState(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const displayNameRef = useRef<TextFieldProps>(null)
  const countryRef = useRef<TextFieldProps>(null)
  const operatorRef = useRef<TextFieldProps>(null)
  const totalStationsRef = useRef<TextFieldProps>(null)
  const termsRef = useRef<HTMLInputElement>(null)

  const pageRoutes = usePageRoutes()
  const { getCoreApiClient } = useCoreApi()
  const { loginUser } = useUser()

  const handleOnSignUpClick = async () => {
    try {
      setIsFormDisabled(true)

      if (!displayNameRef.current || !countryRef.current || !operatorRef.current || !totalStationsRef.current || !termsRef.current) {
        logger.error('Reference to form field is missing', {
          refs: [displayNameRef.current, countryRef.current, operatorRef.current, totalStationsRef.current, termsRef.current]
        })
        throw new Error('Reference to form field is missing')
      }
  
      const displayName = displayNameRef.current.value as string
      const country = countryRef.current.value as string
      const operator = operatorRef.current.value as string
      const totalStations = totalStationsRef.current.value as string
      const terms = termsRef.current.checked as boolean
  
      if (!providerUserInfo) {
        throw new Error('Provider user info missing')
      }

      if (terms === false) {
        throw new Error('Terms and conditions not accepted')
      }

      let formErrorsFound: FormErrors = {}

      if (operator === '') {
        formErrorsFound.operator = 'FIELD_REQUIRED'
      } else if (operator !== 'true') {
        formErrorsFound.operator = 'MUST_BE_GAS_STATION_OPERATOR'
      }

      if (country === '') {
        formErrorsFound.country = 'FIELD_REQUIRED'
      } else if (country !== 'PH') {
        formErrorsFound.country = 'MUST_BE_OPERATING_IN_PHILIPPINES'
      }

      if (totalStations === '') {
        formErrorsFound.totalStations = 'FIELD_REQUIRED'
      }

      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      // No errors found
      setFormErrors({})

      const coreApi = await getCoreApiClient()
      const createUserResponse = await coreApi.post('/users', {
        email: providerUserInfo.email,
        displayName,
        country,
        totalStations,
        isGasStationOperator: operator === 'true',
        provider: 'GOOGLE',
        providerUid: providerUserInfo.uid,
      }, {
        params: {
          type: 'business'
        }
      }) as CreateUserResponse

      if (!createUserResponse || !createUserResponse.data || !createUserResponse.data.result) {
        throw errcode(new Error('Missing result in CreateUserResponse'), 'CreateUserFailed')
      }

      const createUserResult = createUserResponse.data.result

      const currentUser = await loginUser(createUserResult.providerUid)

      navigate(pageRoutes.getPrivatePages(currentUser.businessAccount?.businessAccountId || 'undefined').home, { replace: true })
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Business Account Registration failed', { errMessage, errCode })

      let formErrorsFound: FormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      if (errCode === 'UserAlreadyExists') {
        formErrorsFound.submit = 'USER_ALREADY_EXISTS'
      }

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleOnGoogleRegisterBtnClick = async () => {
    try {
      setIsProviderAuthenticating(true)

      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      const result = await signInWithPopup(auth, provider)

      if (!result.user) {
        throw new Error('Registration response does not include `user` property')
      }

      if (!result.user.email) {
        throw new Error('Google sign uo did not return an email address')
      }

      setProviderUserInfo({
        uid: result.user.uid,
        email: result.user.email,
        isEmailVerified: result.user.emailVerified,
        displayName: result.user.displayName || `user${Date.now()}`,
      })

      setIsProviderAuthenticating(false)
    } catch (err: any) {
      logger.error('Firebase registration failed', {
        errCode: err.code,
        errMessage: err.message
      })

      if (err.code === 'auth/popup-closed-by-user') {
        setIsProviderAuthenticating(false)
      }
    }
  }

  const handleOnOperatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGasStationOperator(event.target.value === 'true')
  }

  const handleOnTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked)
  }

  const handleOnEmailOptInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmailOptInAccepted(event.target.checked)
  }

  const GoogleBtn = ({ children }: GoogleBtnProps) => {
    return <button className="appui__btn appui__auth-btn" onClick={handleOnGoogleRegisterBtnClick}>{ children }</button>
  }

  const TermsLabel = () => {
    return <Typography variant="body1">I accept the <a href="#tnc">Privacy Policy</a> and <a href="#tnc">Terms of Service</a></Typography>
  }

  const EmailOptInLabel = () => {
    return <Typography variant="body1">I agree to receive system related emails and notifications.</Typography>
  }

  if (formErrors.country === 'MUST_BE_OPERATING_IN_PHILIPPINES' || formErrors.operator === 'MUST_BE_GAS_STATION_OPERATOR') {
    return <AuthContainer>
      <Container maxWidth="sm">
        <Box maxWidth={600}>
          <Box mb={8} textAlign="center">
            <img src={imgRegistrationFailed} alt="Must be operating in the Philippines" width="100%" height="auto" />
          </Box>
          <Box mb={5}>
            <>
              {formErrors.country === 'MUST_BE_OPERATING_IN_PHILIPPINES' &&
                <Alert severity="error">
                  Fewlsy for Business is not yet available outside of the Philippines.
                </Alert>
              }
            </>
            <>
              {formErrors.operator === 'MUST_BE_GAS_STATION_OPERATOR' &&
                <Alert severity="error">
                  Fewlsy for Business is only available to gas station businesses.
                </Alert>
              }
            </>
          </Box>
          <Box mb={3}>
            <Typography>Thanks for your interest in Fewlsy for Businesses, unfortunately, Fewlsy for Businesses is only available to gas station businesses based in the Philippines at this time.</Typography>
          </Box>
          <Box>
            <Typography>We are working hard to expand the availability of Fewlsy for Businesses, so check back again from time to time.</Typography>
          </Box>
        </Box>
      </Container>
    </AuthContainer>
  }

  if (!isProviderAuthenticating && providerUserInfo) {
    return <AuthContainer>
      <Container maxWidth="sm">
        <Box mb={3}>
          <img src={imgAppIcon} width={64} height={64} alt="Fewlsy Icon" />
        </Box>
        <Box mb={5}>
          <Typography variant="h5">Create a Fewlsy<br />Business Account</Typography>
        </Box>
        <>
          {formErrors.submit === 'REQUEST_FAILED' &&
            <Box mb={5}>
              <Alert severity="error">
                Sorry, something went wrong while creating your account.
              </Alert>
            </Box>
          }
        </>
        <>
          {formErrors.submit === 'USER_ALREADY_EXISTS' &&
            <Box mb={5}>
              <Alert severity="error">
                This email address has already been registered. <Link to="/login">Log in</Link> instead?
              </Alert>
            </Box>
          }
        </>
        <Stack direction="column" spacing={5}>
          <Box>
            <TextField disabled fullWidth color="primary" label="Email Address" variant="outlined" defaultValue={providerUserInfo.email} />
          </Box>
          <Box>
            <TextField disabled={isFormDisabled} fullWidth color="primary" inputRef={displayNameRef} label="Your Display Name" variant="outlined" defaultValue={providerUserInfo.displayName} />
          </Box>
          <Box>
            <TextField
              fullWidth
              select
              label="Do you actively operate a gas station business?"
              defaultValue=""
              color="primary"
              disabled={isFormDisabled}
              inputRef={operatorRef}
              onChange={handleOnOperatorChange}
              error={formErrors.operator === 'FIELD_REQUIRED'}
              helperText={formErrors.operator === 'FIELD_REQUIRED' ? 'Field required' : undefined}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </Box>
          <>
            <Box display={!isGasStationOperator ? 'none' : 'block'}>
              <TextField
                fullWidth
                select
                label="How many gas stations do you operate?"
                defaultValue=""
                color="primary"
                disabled={isFormDisabled}
                inputRef={totalStationsRef}
                error={formErrors.totalStations === 'FIELD_REQUIRED'}
                helperText={formErrors.totalStations === 'FIELD_REQUIRED' ? 'Field required' : undefined}
              >
                {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((v, i) => {
                    return <MenuItem key={i} value={v}>{v === 11 ? '11 or more' : v}</MenuItem>
                  })
                }
              </TextField>
            </Box>
            <Box display={!isGasStationOperator ? 'none' : 'block'}>
              <TextField
                fullWidth
                select
                label="What country do you operate in?"
                defaultValue=""
                color="primary"
                disabled={isFormDisabled}
                inputRef={countryRef}
                error={formErrors.country === 'FIELD_REQUIRED'}
                helperText={formErrors.country === 'FIELD_REQUIRED' ? 'Field required' : undefined}
              >
                <MenuItem value="PH">Philippines</MenuItem>
                <MenuItem value="OTHER">I operate outside of the Philippines</MenuItem>
              </TextField>
            </Box>
          </>
          <Box>
            <FormControlLabel control={<Checkbox disabled={isFormDisabled} inputRef={termsRef} color="primary" onChange={handleOnTermsChange} />} label={<TermsLabel />} />
            <Box>
              <FormControlLabel control={<Checkbox disabled={isFormDisabled} color="primary" onChange={handleOnEmailOptInChange} />} label={<EmailOptInLabel />} />
            </Box>
          </Box>
          <Box textAlign="right">
            <>
              {(!isTermsAccepted || !isEmailOptInAccepted) &&
                <Button disabled color="primary" variant="contained">Sign up</Button>
              }
            </>
            <>
              {(isTermsAccepted && isEmailOptInAccepted) &&
                <Button disabled={isFormDisabled} color="primary" variant="contained" onClick={handleOnSignUpClick}>{isFormDisabled ? 'Signing up...' : 'Sign up'}</Button>
              }
            </>
          </Box>
        </Stack>
      </Container>
    </AuthContainer>
  }

  if (isProviderAuthenticating) {
    return <AuthContainer>
      <Box mb={3}>
        <img src={imgAppIcon} width={64} height={64} alt="Fewlsy Icon" />
      </Box>
      <Box>
        <Typography variant="h5">Authenticating</Typography>
      </Box>
    </AuthContainer>
  }

  return <AuthContainer maxWidth="xl">
    <Box mb={5}>
      <img src={imgAppIcon} width={128} height={128} alt="Fewlsy Icon" />
    </Box>
    <>
      {!accountType &&
        <>
          <Box>
            <Typography variant="h5">Create a new account</Typography>
          </Box>
          <Box mb={10}>
            <Stack direction="column" spacing={5} alignItems="center">
              <Box>
                <Stack direction="column" spacing={5} alignItems="center">
                  <Box>
                    <Typography>Choose your account type</Typography>
                  </Box>
                  <Box>
                    <Stack direction="row" spacing={3}>
                      <Box>
                        <Button sx={{ minWidth: 100 }} fullWidth variant="outlined" color="primary" onClick={() => setAccountType('PERSONAL')}>Personal Account</Button>
                      </Box>
                      <Box>
                        <Button sx={{ minWidth: 100 }} fullWidth variant="contained" color="primary" onClick={() => setAccountType('BUSINESS')}>Business Account</Button>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Alert severity="info">Choose <b>Business Account</b> if you are gas station operator that wants to be listed on Fewlsy.</Alert>
              </Box>
            </Stack>
          </Box>
        </>
      }
    </>
    <>
      {accountType === 'BUSINESS' &&
        <>
          <Box mb={5} maxWidth={600}>
            <Typography variant="h5">Create a Fewlsy Business Account</Typography>
          </Box>
          <Box mb={5}>
            <GoogleBtn>
              <img src={imgGoogleIcon} width={32} height={32} alt="Sign up with Google" />
              <span className="appui__auth-btn-text">Sign up with Google</span>
            </GoogleBtn>
          </Box>
        </>
      }
    </>
    <>
      {accountType === 'PERSONAL' &&
        <Box mb={10}>
          <Box maxWidth={600}>
            <Alert severity="info">
              <AlertTitle><b>Download Fewlsy</b></AlertTitle>
              To register for a Fewlsy Personal Account you will need to download the free Fewlsy mobile app for Android or iOS and register from there.
            </Alert>
          </Box>
        </Box>
      }
    </>
    <Box>
      <Typography variant="body1">Already registered? <Button variant="text" color="primary" component={Link as any} to="/login">Log in</Button></Typography>
    </Box>
  </AuthContainer>
}
