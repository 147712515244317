import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles'
import styled from 'styled-components'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MuiContainer from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Logger from '../services/logger'
import * as Types from '../types'
import useCoreApi from '../hooks/useCoreApi'

const logger = new Logger({
  filePath: '@/components/GasStationProductHistory'
})

type StyledTheme = {
  theme: Theme,
}

type GetProductHistoryResponse = {
  data: {
    result?: Types.ProductHistory[]
  }
}

type FuelProductsList = {
  [key: string]: Types.FuelProduct
}

type GasStationProductHistoryProps = {
  fuelProducts: FuelProductsList
  lastUpdated: Date
}

const Container = styled(MuiContainer)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      margin-top: ${theme.spacing(5)};
      margin-bottom: ${theme.spacing(5)};
      margin-left: 0;
      margin-right: 0;
    }
  `}
`

const fuelTypes = { DIESEL: 'Diesel', GASOLINE: 'Gasoline' }
const fuelSubtypes = { REGULAR: 'Regular', PREMIUM: 'Premium' }

export default function GasStationProductHistory(props: GasStationProductHistoryProps) {
  const { fuelProducts, lastUpdated } = props
  const fuelProductList: Types.FuelProduct[] = Object.keys(fuelProducts).map((k) => {
    return fuelProducts[k]
  })

  const { businessAccountId, gasStationId } = useParams()
  const { getCoreApiClient } = useCoreApi()

  const [data, setData] = useState<Types.ProductHistory[]>()
  const [lastFetch, setLastFetch] = useState<Date>(new Date())

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getProductHistoryResponse = await coreApi.get(`/business/${businessAccountId}/gas-stations/${gasStationId}/fuel-products/history`) as GetProductHistoryResponse
  
        if (!isSubscribed) {
          return
        }
  
        if (getProductHistoryResponse?.data?.result) {
          setData(getProductHistoryResponse.data.result)
          setLastFetch(new Date())
        }
      } catch (err) {
        logger.error('GasStationProductHistory failed to initialize', { err })
      }
    }

    if (!data || lastFetch < lastUpdated) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [data, getCoreApiClient, businessAccountId, gasStationId, lastUpdated, lastFetch])

  if (!data || data.length < 1) {
    return <React.Fragment />
  }

  return <Container>
    <Box mb={3}>
      <Typography variant="h5" component="p">Change History</Typography>
      <Typography variant="body2" component="p" color="GrayText">Only the 15 most recent changes will be displayed.</Typography>
    </Box>
    <Box>
      <>
        {data &&
          <TableContainer component={Paper}>
            <Table  sx={{ minWidth: 800 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Change Type</TableCell>
                  <TableCell align="right">Old</TableCell>
                  <TableCell align="right">New</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((productHistory) => {
                  let typeLabel = ''
                  let oldValue: any[] = []
                  let newValue: any[] = []

                  const fuelProduct = fuelProductList.filter(f => f.uuid === productHistory.fuelProductId)[0]
                  const productLabel = `${fuelTypes[fuelProduct.type]} ${fuelSubtypes[fuelProduct.subtype]}`

                  if (productHistory.type === 'UPDATED') {
                    if (productHistory.changes.newDescription !== undefined) {
                      typeLabel = 'Info'
                      oldValue.push(`"${productHistory.changes.oldDescription}"`)
                      newValue.push(`"${productHistory.changes.newDescription}"`)
                    }

                    if (productHistory.changes.newPrice && productHistory.changes.oldPrice) {
                      typeLabel += typeLabel.length < 1 ? 'Price' : ' + Price'
                      if (productHistory.changes.newPrice !== productHistory.changes.oldPrice) {
                        oldValue.push(`₱ ${productHistory.changes.oldPrice?.toFixed(2)}`)
                      }

                      if (productHistory.changes.newPrice > productHistory.changes.oldPrice) {
                        newValue.push(<Stack direction="row" spacing={0} justifyContent="flex-end" alignItems="center">
                          <Typography>
                            {`₱ ${productHistory.changes.newPrice?.toFixed(2)}`}
                          </Typography>
                          <ArrowUpwardIcon />
                        </Stack>)
                      } else if (productHistory.changes.newPrice < productHistory.changes.oldPrice) {
                        newValue.push(<Stack direction="row" spacing={0} justifyContent="flex-end" alignItems="center">
                          <Typography>
                            {`₱ ${productHistory.changes.newPrice?.toFixed(2)}`}
                          </Typography>
                          <ArrowDownwardIcon />
                        </Stack>)
                      }
                    }
                  }

                  if (productHistory.type === 'PUBLISHED') {
                    typeLabel = 'Status'
                    oldValue.push(<Typography color="GrayText">Unpublished</Typography>)
                    newValue.push(<Chip label="Published" color="info" size="small" variant="filled" />)
                  }

                  if (productHistory.type === 'UNPUBLISHED') {
                    typeLabel = 'Status'
                    oldValue.push(<Typography color="GrayText">Published</Typography>)
                    newValue.push(<Chip label="Unpublished" color="warning" size="small" variant="filled" />)
                  }

                  return <TableRow
                    key={productHistory.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography><b>{productLabel}</b></Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Box><Typography variant="body2">{typeLabel}</Typography></Box>
                    </TableCell>
                    <TableCell align="right">
                      {
                        oldValue.map((v, i) => {
                          return <Box key={i}>{v}</Box>
                        })
                      }
                    </TableCell>
                    <TableCell align="right">
                      {
                        newValue.map((v, i) => {
                          return <Box key={i}>{v}</Box>
                        })
                      }
                    </TableCell>
                    <TableCell align="right">{productHistory.updatedByUser?.displayName || '-'}</TableCell>
                    <TableCell align="right">
                      <Tooltip title={`${new Date(productHistory.createdAt!).toLocaleString()}`} arrow>
                        <Box>
                          <Typography variant="body2">{new Date(productHistory.createdAt!).toDateString()}</Typography>
                        </Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>
    </Box>
  </Container>
}
