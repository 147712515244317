import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import errcode from 'err-code'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import TextField, { TextFieldProps} from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import styled from 'styled-components'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import GasStationProductHistory from './GasStationProductHistory'

import Logger from '../services/logger'
import useCoreApi from '../hooks/useCoreApi'
import { useUser } from '../hooks/useUser'
import * as Types from '../types'

const logger = new Logger({
  filePath: '@/components/GasStationProducts'
})

type StyledTheme = {
  theme: Theme,
}

type GetFuelProductsResponse = {
  data: {
    result?: Types.FuelProduct[]
  }
}

type PutFuelProductResponse = {
  data: {
    result?: Types.FuelProduct
  }
}

type PutFuelProductPayload = {
  price: string
  description: string
}

type FuelProductsList = {
  [key: string]: Types.FuelProduct
}

type GasStationProductsProps = {
  gasStation: Types.GasStation
  gasStationId: string
}

type ProductMenuUpdateDialogProps = {
  fuelProduct: Types.FuelProduct
  open: boolean
  onClose: (fuelProductUpdated?: boolean) => void
}

type ProductMenuUpdateFormErrors = {
  price?: 'FIELD_REQUIRED' | 'NO_ZERO_VALUE'
  submit?: 'REQUEST_FAILED'
}

const FuelProductCard = styled(Card)<CardProps>(({ theme }) => ({
  marginRight: 0,
  marginBottom: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
    width: '300px'
  },
}))


const FuelProductCardContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      margin: ${theme.spacing(3)};
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`

const FuelProductCardSkeleton = styled(Skeleton)`
  ${({ theme }: StyledTheme) => `
    &.MuiSkeleton-root {
      margin-right: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`

const FuelProductCardHeader = styled(CardHeader)`
  ${({ theme }: StyledTheme) => `
    &.MuiCardHeader-root {
      padding-bottom: 0;
    }
  `}
`

const PriceTypography = styled(Typography)`
  ${({ theme }: StyledTheme) => `
    &.MuiTypography-root {
      font-size: 44px;
      line-height: 44px;
    }
  `}
`

const ProductMenuItem = styled(MenuItem)`
  ${({ theme }: StyledTheme) => `
    &.MuiMenuItem-root {
      width: 150px;
    }
  `}
`

const fuelTypes = { DIESEL: 'Diesel', GASOLINE: 'Gasoline' }
const fuelSubtypes = { REGULAR: 'Regular', PREMIUM: 'Premium' }

function ProductMenuUpdateDialog(props: ProductMenuUpdateDialogProps) {
  const { open, onClose, fuelProduct } = props
  const { getCoreApiClient } = useCoreApi()
  const { businessAccountId } = useParams()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const descriptionRef = useRef<TextFieldProps>(null)
  const priceRef = useRef<TextFieldProps>(null)

  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [formErrors, setFormErrors] = useState<ProductMenuUpdateFormErrors>({})

  const handleOnClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!isFormDisabled) {
      if (reason !== 'backdropClick') {
        onClose()
      }
    }
  }

  const handleOnCancelClick = () => {
    if (!isFormDisabled) {
      onClose()
    }
  }

  const handleOnPriceBlur = () => {
    if (priceRef?.current?.value) {
      priceRef.current.value = parseFloat(`${priceRef.current.value}`).toFixed(2)
    }
  }

  const saveFuelProduct = async () => {
    try {
      setIsFormDisabled(true)

      if (!descriptionRef.current ||
        !priceRef.current
      ) {
        logger.error('Reference to form field is missing', {
          refs: [
            descriptionRef.current,
            priceRef.current,
          ],
        })
        throw new Error('Reference to form field is missing')
      }

      const description = descriptionRef.current.value as string
      const price = priceRef.current.value as string

      let formErrorsFound: ProductMenuUpdateFormErrors = {}

      if (price.trim().length < 1) {
        formErrorsFound.price = 'FIELD_REQUIRED'
      } else if (parseFloat(price) === 0) {
        formErrorsFound.price = 'NO_ZERO_VALUE'
      }

      if (Object.keys(formErrorsFound).length > 0) {
        setIsFormDisabled(false)
        throw errcode(new Error('Form validation errors found'), 'FormSubmitError', { formErrorsFound })
      }

      const payload: PutFuelProductPayload = {
        description,
        price,
      }

      const newPrice = price
      const currentPrice = parseFloat(`${fuelProduct.price}`).toFixed(2)

      const newDescription = description
      const currentDescription = fuelProduct.description
  
      if (currentPrice === newPrice && currentDescription === newDescription) {
        // No change in price and description.
        setIsFormDisabled(false)
        onClose(false)
        return
      }

      const coreApi = await getCoreApiClient()
      await coreApi.put(`/business/${businessAccountId}/gas-stations/${fuelProduct.gasStationId}/fuel-products/${fuelProduct.uuid}`, {
        ...payload
      }) as PutFuelProductResponse

      setIsFormDisabled(false)
      onClose(true)
    } catch (err: any) {
      const errCode = err.response?.data?.error?.code || err.code
      const errMessage = err.response?.data?.error?.message || err.message

      logger.error('Save fuel product failed', { errMessage, errCode })

      let formErrorsFound: ProductMenuUpdateFormErrors = {}

      if (errCode === 'FormSubmitError') {
        setFormErrors({
          ...err.formErrorsFound
        })
        setIsFormDisabled(false)
        return
      }

      formErrorsFound.submit = 'REQUEST_FAILED'

      setFormErrors({ ...formErrorsFound })
      setIsFormDisabled(false)
    }
  }

  const handleOnSaveClick = () => {
    saveFuelProduct()
  }

  let priceErrorMessage = ''
  if (formErrors.price === 'FIELD_REQUIRED') {
    priceErrorMessage = 'Field required'
  } else if (formErrors.price === 'NO_ZERO_VALUE') {
    priceErrorMessage = 'Price can\'t be zero'
  }

  return <Dialog
    open={open}
    onClose={handleOnClose}
    maxWidth="md"
  >
    <Box p={2} minWidth={mdUp ? 400 : 0}>
      <Box mb={2}>
        <Typography variant="h6" component="h2">
          {`Update ${fuelTypes[fuelProduct.type]} ${fuelSubtypes[fuelProduct.subtype]}`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            size="small"
            label="Description"
            variant="outlined"
            defaultValue={fuelProduct.description}
            disabled={isFormDisabled}
            inputRef={descriptionRef}
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            size="small"
            label="Price"
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">₱</InputAdornment>,
              endAdornment: <InputAdornment position="start">/liter</InputAdornment>,
            }}
            type="number"
            onBlur={handleOnPriceBlur}
            defaultValue={parseFloat(`${fuelProduct.price}`).toFixed(2)}
            disabled={isFormDisabled}
            inputRef={priceRef}
            error={formErrors.price !== undefined}
            helperText={formErrors.price !== undefined ? priceErrorMessage : undefined}
          />
        </Box>
        <Box>
          <Stack direction="row" spacing={4} justifyContent="flex-end">
            <Button variant="contained" color="error" disabled={isFormDisabled} onClick={handleOnCancelClick}>Cancel</Button>
            <Button variant="text" color="primary" disabled={isFormDisabled} onClick={handleOnSaveClick}>{isFormDisabled ? 'Saving...' : 'Save'}</Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  </Dialog>
}

export default function GasStationProducts(props: GasStationProductsProps) {
  const { gasStationId, gasStation } = props
  const { getCoreApiClient } = useCoreApi()
  const { businessAccountId } = useParams()
  const { currentUser } = useUser()

  const [fuelProducts, setFuelProducts] = useState<FuelProductsList>()
  const [productMenuAnchorEl, setProductMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [productMenuInfo, setProductMenuInfo] = useState<Types.FuelProduct>()
  const [isProductMenuUpdateDialogOpen, setIsProductMenuUpdateDialogOpen] = useState(false)
  const [isProductUpdating, setIsProductUpdating] = useState<string[]>([])
  const [productHistoryLastUpdated, setProductHistoryLastUpdated] = useState<Date>(new Date())

  const isProductMenuOpen = Boolean(productMenuAnchorEl)

  const handleProductMenuClick = (event: React.MouseEvent<HTMLButtonElement>, fuelProduct: Types.FuelProduct) => {
    setProductMenuAnchorEl(event.currentTarget)
    setProductMenuInfo(fuelProduct)
  }

  const getFuelProducts = async () => {
    const coreApi = await getCoreApiClient()
    const getFuelProductsResponse = await coreApi.get(`/business/${businessAccountId}/gas-stations/${gasStationId}/fuel-products`) as GetFuelProductsResponse

    if (getFuelProductsResponse.data.result) {
      const newFuelProducts: FuelProductsList = {}
      getFuelProductsResponse.data.result.forEach((fuelProduct) => {
        const fuelProductKey: 'DIESEL_REGULAR' | 'DIESEL_PREMIUM' | 'GASOLINE_REGULAR' | 'GASOLINE_PREMIUM' = `${fuelProduct.type}_${fuelProduct.subtype}`
        newFuelProducts[fuelProductKey] = fuelProduct
      })

      setFuelProducts(newFuelProducts)
    }
  }

  const handleProductMenuUpdateDialogClose = (fuelProductUpdated: boolean = false) => {
    setIsProductMenuUpdateDialogOpen(false)

    if (fuelProductUpdated) {
      getFuelProducts()
      setProductHistoryLastUpdated(new Date())
    }
  }

  const handleProductMenuClose = () => {
    setProductMenuAnchorEl(null)
  }

  const handleProductMenuUpdateClick = () => {
    setIsProductMenuUpdateDialogOpen(true)
    setProductMenuAnchorEl(null)
  }

  const updateProductStatus = async (publishingType: 'publish' | 'unpublish') => {
    try {
      if (!productMenuInfo) {
        throw errcode(new Error('Missing product menu info'), 'MissingProductMenuInfo')
      }

      setIsProductUpdating([
        ...isProductUpdating,
        productMenuInfo.uuid,
      ])

      const coreApi = await getCoreApiClient()
      const putFuelProductResponse = await coreApi.put(`/business/${businessAccountId}/gas-stations/${gasStationId}/fuel-products/${productMenuInfo.uuid}/${publishingType}`) as PutFuelProductResponse

      if (putFuelProductResponse?.data?.result) {
        await getFuelProducts()
        const newIsProductUpdating = isProductUpdating.filter(id => id === productMenuInfo.uuid)
        setIsProductUpdating([...newIsProductUpdating])

        setProductHistoryLastUpdated(new Date())
      }
    } catch (err) {
      throw err
    }
  }

  const handleProductMenuPublish = () => {
    setProductMenuAnchorEl(null)

    if (gasStation.status === 'ACTIVE') {
      updateProductStatus('publish')
    } else {
      alert('This gas station location is not yet active')
    }
  }

  const handleProductMenuUnpublish = () => {
    setProductMenuAnchorEl(null)
    updateProductStatus('unpublish')
  }

  useEffect(() => {
    let isSubscribed = true

    const ini = async () => {
      const coreApi = await getCoreApiClient()
      const getFuelProductsResponse = await coreApi.get(`/business/${businessAccountId}/gas-stations/${gasStationId}/fuel-products`) as GetFuelProductsResponse

      if (!isSubscribed) {
        return false
      }

      if (getFuelProductsResponse.data.result) {
        const newFuelProducts: FuelProductsList = {}
        getFuelProductsResponse.data.result.forEach((fuelProduct) => {
          const fuelProductKey: 'DIESEL_REGULAR' | 'DIESEL_PREMIUM' | 'GASOLINE_REGULAR' | 'GASOLINE_PREMIUM' = `${fuelProduct.type}_${fuelProduct.subtype}`
          newFuelProducts[fuelProductKey] = fuelProduct
        })

        setFuelProducts(newFuelProducts)
      }
    }

    if (!fuelProducts) {
      ini()
    }

    return () => {
      isSubscribed = false
    }
  }, [fuelProducts, gasStationId, businessAccountId, getCoreApiClient])

  let isEditor = true

  if (!currentUser?.businessAccount?.isAdmin && currentUser?.businessAccount?.permissions) {
    const currentUserPermissions = currentUser.businessAccount.permissions.filter(p => p.type === 'gas-station-permission' && p.gasStationId === gasStationId)[0]
    if (currentUserPermissions && currentUserPermissions.type === 'gas-station-permission') {
      if (currentUserPermissions.products === 'VIEWER') {
        isEditor = false
      }
    }
  }

  return <>
    <FuelProductCardContainer>
      <>
        {!fuelProducts &&
          <>
            <FuelProductCardSkeleton variant="rounded" width={300} height={128} />
            <FuelProductCardSkeleton variant="rounded" width={300} height={128} />
            <FuelProductCardSkeleton variant="rounded" width={300} height={128} />
            <FuelProductCardSkeleton variant="rounded" width={300} height={128} />
          </>
        }
      </>
      <>
        {fuelProducts &&
          ['DIESEL_REGULAR', 'DIESEL_PREMIUM', 'GASOLINE_REGULAR', 'GASOLINE_PREMIUM'].map((fuelProductKey) => {
            const fuelProduct = fuelProducts[fuelProductKey]

            let isPublished = true
            if (fuelProduct.status !== 'ACTIVE') {
              isPublished = false
            }

            if (!fuelProduct.price) {
              isPublished = false
            }

            let hasPriceIssue = false
            if (!fuelProduct.price || fuelProduct.price === 0) {
              hasPriceIssue = true
            }

            let price = '₱-.--'
            if (!hasPriceIssue) {
              price = '₱' + parseFloat(`${fuelProduct.price}`).toFixed(2)
            }

            let priceColor = 'error'
            if (!hasPriceIssue) {
              priceColor = isPublished ? 'secondary' : 'GrayText'
            }

            return <FuelProductCard key={fuelProduct.uuid}>
              <>
                {isProductUpdating.includes(fuelProduct.uuid) &&
                  <LinearProgress />
                }
              </>
              <FuelProductCardHeader
                action={isEditor ?
                  <IconButton onClick={(e) => handleProductMenuClick(e, fuelProduct)} disabled={isProductUpdating.includes(fuelProduct.uuid)}>
                    <MoreVertIcon />
                  </IconButton> : undefined
                }
                title={`${fuelTypes[fuelProduct.type]} ${fuelSubtypes[fuelProduct.subtype]}`}
                subheader={fuelProduct.description.length > 0 ? fuelProduct.description : 'No Description'}
              />
              <CardContent>
                <Box mb={2}>
                  <>
                    {hasPriceIssue &&
                      <>
                        <Tooltip title="Price needs to be set before you can publish this product." arrow>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Box>
                              <Stack direction="row" alignItems="flex-end" spacing={0}>
                                <PriceTypography color={priceColor}>{price}</PriceTypography>
                                <Typography variant="h6" component="span" color="error">/liter</Typography>
                              </Stack>
                            </Box>
                            <InfoOutlinedIcon color="error" />
                          </Stack>
                        </Tooltip>
                      </>
                    }
                  </>
                  <>
                    {!hasPriceIssue &&
                      <>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Box>
                            <Stack direction="row" alignItems="flex-end" spacing={0}>
                              <PriceTypography color={priceColor}>{price}</PriceTypography>
                              <Typography variant="h6" component="span" color="GrayText">/liter</Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </>
                    }
                  </>
                </Box>
                <>
                  {!isPublished &&
                    <Box mb={2}>
                      <Tooltip title="This product will not appear in the Fewlsy app" arrow>
                        <Chip label="Unpublished" color="warning" size="small" variant="outlined" />
                      </Tooltip>
                    </Box>
                  }
                </>
                <>
                  {isPublished &&
                    <Box mb={2}>
                      <Tooltip title="This product will appear in the Fewlsy app" arrow>
                        <Chip label="Published" color="info" size="small" variant="filled" />
                      </Tooltip>
                    </Box>
                  }
                </>
              </CardContent>
            </FuelProductCard>
          })
        }
      </>
    </FuelProductCardContainer>
    <>
      {fuelProducts &&
        <GasStationProductHistory fuelProducts={fuelProducts} lastUpdated={productHistoryLastUpdated} />
      }
    </>
    <Menu
      anchorEl={productMenuAnchorEl}
      open={isProductMenuOpen}
      onClose={handleProductMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box>
        <>
          {productMenuInfo?.status === 'DEACTIVATED' &&
            <ProductMenuItem onClick={handleProductMenuUpdateClick}>Update</ProductMenuItem>
          }
        </>
        <>
          {productMenuInfo?.status === 'ACTIVE' &&
            <Box>
              <Box width={150}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <ProductMenuItem disabled>Update</ProductMenuItem>
                  <Box pr={1} display="flex" justifyContent="center" alignItems="center">
                    <Tooltip title="You must unpublish this product before you can update its information." arrow>
                      <InfoOutlinedIcon color="warning" />
                    </Tooltip>
                  </Box>
                </Stack>
              </Box>
            </Box>
          }
        </>
        <Box my={1}>
          <Divider />
        </Box>
        <Box>
          <Box>
            {gasStation.status === 'REJECTED' &&
              <Box>
                <Box width={150}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <ProductMenuItem disabled>Publish</ProductMenuItem>
                    <Box pr={1} display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title="Publishing not allowed - gas station location was rejected." arrow>
                        <InfoOutlinedIcon color="warning" />
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            }
          </Box>
          <Box>
            {gasStation.status === 'PENDING_REVIEW' &&
              <Box>
                <Box width={150}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <ProductMenuItem disabled>Publish</ProductMenuItem>
                    <Box pr={1} display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title="Publishing not allowed - gas station location is still pending review." arrow>
                        <InfoOutlinedIcon color="warning" />
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            }
          </Box>
          <Box>
            {(gasStation.status === 'ACTIVE' && productMenuInfo?.status === 'DEACTIVATED' && productMenuInfo?.price === 0) &&
              <Box>
                <Box width={150}>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <ProductMenuItem disabled>Publish</ProductMenuItem>
                    <Box pr={1} display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title="Publishing not allowed - price needs to be set before you can publish this product." arrow>
                        <InfoOutlinedIcon color="warning" />
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            }
          </Box>
          <Box>
            {(gasStation.status === 'ACTIVE' && productMenuInfo?.status === 'DEACTIVATED' && productMenuInfo?.price !== 0) &&
              <ProductMenuItem onClick={handleProductMenuPublish}>Publish</ProductMenuItem>
            }
          </Box>
          <Box>
            {(gasStation.status === 'ACTIVE' && productMenuInfo?.status === 'ACTIVE' && productMenuInfo?.price && productMenuInfo?.price !== 0) &&
              <ProductMenuItem onClick={handleProductMenuUnpublish}>Unpublish</ProductMenuItem>
            }
          </Box>
        </Box>
      </Box>
    </Menu>
    <>
      {productMenuInfo && isProductMenuUpdateDialogOpen &&
        <ProductMenuUpdateDialog
          fuelProduct={productMenuInfo}
          open={isProductMenuUpdateDialogOpen}
          onClose={handleProductMenuUpdateDialogClose}
        />
      }
    </>
  </>
}
