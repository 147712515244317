import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import DashboardContent from './DashboardContent'

export default function DashboardInsights() {
  return <DashboardContent>
    <Stack direction="column">
      <Typography>Dashboard</Typography>
      <Typography>Insights</Typography>
      <Typography>Price ranking with nearby competition</Typography>
      <Typography>Search Impressions - Ranking vs nearby competitors based on radius</Typography>
      <Typography>Ranking is based on relevance, rating, distance, and price</Typography>
      <Typography>Price History vs Average Price in radius</Typography>
      <Typography></Typography>
    </Stack>
  </DashboardContent>
}
