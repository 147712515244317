import React from 'react'
import { Link, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// import Logger from '../services/logger'
import usePageRoutes from '../hooks/usePageRoutes'
import DashboardContent from './DashboardContent'

// const logger = new Logger({
//   filePath: '@/components/DashboardHome'
// })

export default function DashboardSettings() {
  // const [something, setSomething] = useState(false)
  const { businessAccountId } = useParams()
  const pageRoutes = usePageRoutes()
  const privatePages = pageRoutes.getPrivatePages(businessAccountId)

  return <DashboardContent>
    <Box p={4}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h5" component="h1">Welcome to the Fewlsy Business dashboard!</Typography>
        </Box>
        <Box>
          Go to <Link to={privatePages.gasStations}>Gas Stations</Link> to get started.
        </Box>
      </Stack>
    </Box>
  </DashboardContent>
}
